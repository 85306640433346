import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import SuncleButton from '../SuncleButton/SuncleButton';
import RoleContext from '../../lib/context/RoleContext';

type EditButtonProps = {
  to: string;
  // condition?: boolean;
};

const EditButton = ({ to }: EditButtonProps) => {
  const { state: { role } } = useContext(RoleContext);
  // if (condition && condition === true) return <Link to={to}><SuncleButton startIcon={<EditIcon />}>Edit</SuncleButton></Link>;
  return role === 'staff' || role === 'admin' ? <Link to={to}><SuncleButton startIcon={<EditIcon />}>Edit</SuncleButton></Link> : null;
};

export default EditButton;
