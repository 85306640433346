import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PageTemplate from '../PageTemplate';
import { Sheet, PendingBar } from '../../components';
import DashboardTable from './DashboardTable';
import useAxios from '../../lib/hooks/useAxios';

const useStyles = makeStyles(() => ({
  tableSubTitle: {
    paddingLeft: 7,
    '&:not(:first-child)': {
      marginTop: 30,
    },
  },
}));

const BasicTable: React.FC<{ data: any[] }> = ({ data }) => {
  return (
    <DashboardTable
      columns={[
        {
          Header: 'Requests',
          accessor: 'submits',
          Cell: ({ cell }: any) => (cell.value || 0),
        },
        {
          Header: 'Approvals',
          accessor: 'staff_approved',
          Cell: ({ cell }: any) => (cell.value || 0),
        },
        {
          Header: 'Leads',
          accessor: 'leads',
          Cell: ({ cell }: any) => (cell.value || 0),
        },
        {
          Header: 'Refunds*',
          accessor: 'valid_claims',
          Cell: ({ row }: any) => (
            <>
              <span className="text-red">{row.original.valid_claims || '-'}</span> /
              <span className="text-green">{row.original.invalid_claims
            || '-'}
              </span> / <span>{row.original.open_claims || '-'}</span>
            </>
          ),
        },
      ]}
      data={data}
    />
  );
};

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const { getDashboard } = useAxios();
  const [todayTableData, setTodayTableData] = useState();
  const [thisWeekTableData, setThisWeekTableData] = useState();
  const [thisBillingCycleTableData, setThisBillingCycleTableData] = useState();
  const [billingCycleTableData, setBillingCycleTableData] = useState();
  const [weeklyHistoryTableData, setWeeklyHistoryTableData] = useState();

  useEffect(() => {
    getDashboard()
      .then((response) => {
        setTodayTableData(response.data.today);
        setThisWeekTableData(response.data.currentWeek);
        setThisBillingCycleTableData(response.data.currentBillingCycle);
        setBillingCycleTableData(response.data.billingCycleHistory);
        setWeeklyHistoryTableData(response.data.weeklyHistory);
      });
  }, []);

  return (
    <PageTemplate pageTitle="Dashboard">
      <Sheet>
        <Typography className={classes.tableSubTitle}>Today</Typography>
        {!todayTableData && <><br /><PendingBar /></>}
        {todayTableData && <BasicTable data={todayTableData} />}

        <Typography className={classes.tableSubTitle}>This Week</Typography>
        {!thisWeekTableData && <><br /><PendingBar /></>}
        {thisWeekTableData && <BasicTable data={thisWeekTableData} />}

        <Typography className={classes.tableSubTitle}>This Billing Cycle</Typography>
        {!thisBillingCycleTableData && <><br /><PendingBar /></>}
        {thisBillingCycleTableData && <BasicTable data={thisBillingCycleTableData} />}

        <Typography className={classes.tableSubTitle}>Billing Cycle History</Typography>
        {!billingCycleTableData && <><br /><PendingBar /></>}
        {billingCycleTableData && (
        <DashboardTable
          columns={[
            {
              Header: 'Billing Date',
              accessor: 'run_date',
              Cell: ({ cell }: any) => {
                const dateOptions = { month: 'short', day: 'numeric' };
                const newDate = new Date(cell.value);
                return newDate.toLocaleString('en-US', dateOptions) || '-';
              },
            },
            {
              Header: 'Requests',
              accessor: 'intro_count',
              Cell: ({ cell }: any) => (cell.value || 0),
            },
            {
              Header: 'Approvals',
              accessor: 'staff_approved',
              Cell: ({ cell }: any) => (cell.value || 0),
            },
            {
              Header: 'Leads',
              accessor: 'leads',
              Cell: ({ cell }: any) => (cell.value || 0),
            },
            {
              Header: 'Refunds*',
              accessor: 'valid_claims',
              Cell: ({ row }: any) => (
                <>
                  <span className="text-red">{row.original.valid_claims || '-'}</span> /
                  <span className="text-green">{row.original.invalid_claims || '-'}
                  </span> / <span>{row.original.open_claims || '-'}</span>
                </>
              ),
            },
          ]}
          data={billingCycleTableData}
          totalRows={billingCycleTableData.length}
        />
        )}

        <Typography className={classes.tableSubTitle}>Weekly History</Typography>
        {!weeklyHistoryTableData && <><br /><PendingBar /></>}
        {weeklyHistoryTableData && (
        <DashboardTable
          columns={[
            {
              Header: 'Starting Date',
              accessor: 'starting_date',
              Cell: ({ cell }: any) => {
                const dateOptions = { month: 'short', day: 'numeric' };
                const newDate = new Date(cell.value);
                return newDate.toLocaleString('en-US', dateOptions) || '-';
              },
            },
            {
              Header: 'Requests',
              accessor: 'submits',
              Cell: ({ cell }: any) => (cell.value || 0),
            },
            {
              Header: 'Approvals',
              accessor: 'staff_approved',
              Cell: ({ cell }: any) => (cell.value || 0),
            },
            {
              Header: 'Leads',
              accessor: 'leads',
              Cell: ({ cell }: any) => (cell.value || 0),
            },
            {
              Header: 'Refunds*',
              accessor: 'valid_claims',
              Cell: ({ row }: any) => (
                <>
                  <span className="text-red">{row.original.valid_claims || '-'}</span> /
                  <span className="text-green">{row.original.invalid_claims || '-'}
                  </span> / <span>{row.original.open_claims || '-'}</span>
                </>
              ),
            },
          ]}
          data={weeklyHistoryTableData}
          totalRows={weeklyHistoryTableData.length}
        />
        )}
        <br />
        <Typography variant="caption">[*] (Valid / Invalid / Open)</Typography>
      </Sheet>
    </PageTemplate>
  );
};

export default Dashboard;
