import React, { useState, useContext } from 'react';
import PageTemplate from '../../PageTemplate';
import { Sheet } from '../../../components';
import StaffContext from '../../../lib/context/StaffContext';
import useAxios from '../../../lib/hooks/useAxios';
import { Staff } from '../../../types/Staff';
import StaffForm from '../components/StaffForm';

const defaultStaff = { name: '', email: '', user_id: '', picture: '', role: '' };

const StaffNew: React.FC = () => {
  const { state: { staff }, dispatch } = useContext(StaffContext);
  const [newStaff, setNewStaff] = useState<Staff>(defaultStaff);
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const { createStaff } = useAxios();

  const submitStaff = (values: Staff) => {
    if (values) {
      setNewStaff(values);
      if (submitMessage) {
        setSubmitMessage(null);
      }

      return createStaff(values)
        .then((response) => {
          if (response.status === 200) {
            dispatch({ type: 'set', payload: { staff: [...staff, response.data] } });
            setSubmitMessage('Successfully added a new staff!');
            setNewStaff(defaultStaff);
          } else {
            setSubmitMessage(
              'Something went wrong, please try to submit again.',
            );
          }
        });
    }
    return null;
  };

  return (
    <PageTemplate pageTitle="Create New Staff">
      <Sheet>
        <StaffForm
          staff={newStaff}
          submitStaff={submitStaff}
          submitMessage={submitMessage}
          setSubmitMessage={setSubmitMessage}
          cancelLinkUrl="/staff"
        />
      </Sheet>
    </PageTemplate>
  );
};

export default StaffNew;
