import React from 'react';
import { Link } from 'react-router-dom';
import InfoDetail from '../../../../components/InfoDetail/InfoDetail';
import PendingBar from '../../../../components/PendingBar/PendingBar';
import { Introduction } from '../../../../types/Introduction';
import { Installer } from '../../../../types/Installer';

type MakeUrlArgs = {
  lat: number;
  lng: number;
};

const makeUrl = ({ lat, lng }: MakeUrlArgs) => `https://suncle.jp/search/?lat=${lat}&lng=${lng}`;

type IntroductionDetailsProps = {
  intro: Introduction;
  installersForIntro: Installer[];
  isLoading: boolean;
};

const IntroductionDetails:React.FC<IntroductionDetailsProps> = ({ intro, installersForIntro, isLoading }) => {
  return (
    <>
      <InfoDetail label="Introduction ID:" data={intro ? intro.intro_id : ''} />
      <InfoDetail label="Affiliate ID:" data={intro ? intro.affiliate_name : 'N/A'} />
      <InfoDetail label="Billing Cycle:" data={intro ? intro.bc_run_date : ''} />
      <InfoDetail
        label="Name:"
        data={`${intro ? intro.family_name : ''} ${intro ? intro.given_name : ''} - ${intro ? intro.family_name_kana : ''} ${intro ? intro.given_name_kana : ''}`}
      />
      <InfoDetail label="Email:" data={intro ? intro.email : ''} />
      <InfoDetail label="Phone:" data={intro ? intro.phone : ''} />
      <InfoDetail label="Preferred contact method:" data={intro ? intro.contact_method : ''} />
      <InfoDetail label="Preferred contact time:" data={intro ? intro.contact_time : ''} />
      <InfoDetail
        label="Address:"
        data={`${intro ? intro.postcode : ''} ${intro ? intro.addr_l1 : ''}${intro ? intro.addr_l2 : ''}`}
      />
      <InfoDetail
        label="Home URL:"
        externalLink={intro && intro.system_attr ? makeUrl(intro.system_attr) : ''}
      />
      <InfoDetail
        label="Home Age:"
        data={intro ? intro.age_home_text : 'N/A'}
      />
      <InfoDetail
        label="System Rating:"
        data={intro ? intro.system_rating : ''}
      />
      <InfoDetail
        label="Panels Count:"
        data={intro ? intro.panels_count : ''}
      />
      <InfoDetail
        label="Recommended Panels Count:"
        data={intro ? intro.recommended_panels_count : ''}
      />
      <InfoDetail
        label="Max Panels Count:"
        data={intro ? intro.max_panels_count : ''}
      />
      <InfoDetail
        label="Estimated System Cost:"
        data={intro ? intro.est_system_cost : ''}
      />
      <InfoDetail
        label="Estimated System Cost Loan:"
        data={intro ? intro.est_system_cost_loan : ''}
      />
      <InfoDetail
        label="Monthly Bill:"
        data={intro ? intro.monthly_bill : ''}
      />
      <InfoDetail
        label="Notes to Installer:"
        data={intro ? intro.notes_to_installer : ''}
      />
      {isLoading && <PendingBar />}
      {installersForIntro
      && installersForIntro.map((installer: Installer, index: number) => (
        <InfoDetail
          key={installer.installer_id}
          label={`Installer ${index + 1}:`}
        >
          Company:<Link to={`/installers/${installer.installer_id}`}>&nbsp;{installer.companyName}</Link><br />
          Admin:&nbsp;{installer.emailForAdmin}
        </InfoDetail>
      ))}
    </>
  );
};

export default IntroductionDetails;
