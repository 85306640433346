import React, { useContext } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SuncleButton from '../SuncleButton/SuncleButton';
import RoleContext from '../../lib/context/RoleContext';

const useStyles = makeStyles(() => ({
  createButton: {
    marginLeft: 16,
    marginTop: 0,
  },
}));

type CreateButtonProps = {
  to: string;
  className?: string;
};

const CreateButton:React.FC<CreateButtonProps> = ({ to, className }) => {
  const { state: { role } } = useContext(RoleContext);
  const classes = useStyles();
  return (
    role === 'staff' || role === 'admin'
      ? (
        <Link to={to}>
          <SuncleButton className={clsx(classes.createButton, className)} startIcon={<AddIcon />}>NEW</SuncleButton>
        </Link>
      )
      : null
  );
};

export default CreateButton;
