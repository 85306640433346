import { useEffect, useState } from 'react';
import useAxios from './useAxios';

const useNextBillingCycleLeads = (installerId: string | undefined | null) => {
  const { getNextBillingCycleLeads } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [leadData, setLeadData] = useState();

  useEffect(() => {
    const fetchLead = async () => {
      if (installerId) {
        setIsLoading(true);
        const { data: fetchedLeadData } = await getNextBillingCycleLeads(installerId);
        setIsLoading(false);
        setLeadData(fetchedLeadData);
      }
    };

    fetchLead();
  }, [installerId]);

  return {
    isLoading,
    leadData,
  };
};

export default useNextBillingCycleLeads;
