/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useTable, useSortBy, usePagination } from 'react-table';
import TablePaginationActions from './TablePaginationActions';

type TableProps = {
  columns: any;
  data: any;
  totalRows: number;
  context: React.Context<any>;
  url: string;
};

const PaginatedTable: React.FC<TableProps> = ({
  columns,
  data,
  totalRows,
  context,
  url,
}) => {
  const {
    state: { currentPage },
    dispatch,
  } = useContext(context);
  const location = useLocation();
  const history = useHistory();
  const {
    getTableProps, headerGroups, prepareRow, page, gotoPage, setPageSize, state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 100 },
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    // Handles page changes when pressing the browser back button
    window.onpopstate = () => {
      if (history.location.search) {
        const pageParam = Number(
          queryString.parse(history.location.search).page,
        );
        dispatch({ type: 'goTo', payload: { nextPage: pageParam - 1 } });
        gotoPage(pageParam - 1);
      } else {
        dispatch({ type: 'goTo', payload: { nextPage: 0 } });
        gotoPage(0);
      }
    };
  }, [currentPage]);

  // Set the last page of results visited
  useEffect(() => {
    if (location.search) {
      const pageParam = Number(queryString.parse(location.search).page);
      dispatch({ type: 'goTo', payload: { nextPage: pageParam - 1 } });
      gotoPage(pageParam - 1);
    } else {
      gotoPage(currentPage);
    }
  }, [data]);

  const headerCell = (column: any) => {
    // Sorting handled by react-table: https://github.com/tannerlinsley/react-table
    if (column.disableSortBy) {
      return (
        <TableCell {...column.getHeaderProps()}>
          {column.render('Header')}
        </TableCell>
      );
    }

    return (
      <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
        <TableSortLabel
          active={column.isSorted || column.isSortedDesc}
          direction={
            (column.isSorted || column.isSortedDesc) && column.isSortedDesc
              ? 'desc'
              : 'asc'
          }
        >
          {column.render('Header')}
        </TableSortLabel>
      </TableCell>
    );
  };

  const headerRow = (headerGroupsArg: any) => headerGroupsArg.map((headerGroup: any) => (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column: any) => headerCell(column))}
    </TableRow>
  ));

  const tableRows = (rows: any[]) => rows.map((row: any) => {
    prepareRow(row);
    return (
      <TableRow {...row.getRowProps()}>
        {row.cells.map((cell: any) => (
          <TableCell {...cell.getCellProps()}>
            {cell.render('Cell')}
          </TableCell>
        ))}
      </TableRow>
    );
  });

  const handleChangePage = (event: any, newPage: number) => {
    dispatch({ type: 'goTo', payload: { nextPage: newPage } });
    if (newPage !== 0) {
      // Pages are 0 indexed, but for the url, it makes sense to start at page 1
      history.push(`/${url}?page=${newPage + 1}`);
    } else {
      history.push(`/${url}`);
    }
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(Number(event.target.value));
    history.push(`/${url}`);
    gotoPage(0);
  };

  return (
    <Table {...getTableProps()}>
      <TableHead>{headerRow(headerGroups)}</TableHead>
      <TableBody>{tableRows(page)}</TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            colSpan={columns.length}
            count={Number(totalRows)}
            rowsPerPage={pageSize}
            page={currentPage}
            rowsPerPageOptions={[10, 50, 100, { label: 'All', value: -1 }]}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default PaginatedTable;
