import React, { createContext, useReducer } from 'react';
import { Installer } from '../../types/Installer';

type State = {
  installers: Installer[];
};

type Action = {
  type: 'set',
  payload: {
    installers: Installer[];
  }
};

type ContextArgs = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const initialState: State = { installers: [] };

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'set':
      return { installers: action.payload.installers };
    default:
      return state;
  }
}

const InstallersContext = createContext({} as ContextArgs);

export const InstallersContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  InstallersContext.displayName = 'InstallersContext';
  return (
    <InstallersContext.Provider value={value}>{children}</InstallersContext.Provider>
  );
};

export default InstallersContext;
