import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(() => ({
  sideNavIcon: {
    color: '#e59924',
  },
  icon: {
    minWidth: 46,
  },
  link: {
    color: '#2b2b2b',
    textDecoration: 'none',
  },
}));

type NavItemInnerProps = {
  label: string | JSX.Element;
  icon: any;
  onClick?: () => void;
};

const NavItemInner:React.FC<NavItemInnerProps> = ({ label, icon: Icon, onClick }) => {
  const classes = useStyles();
  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon className={classes.icon}>
        <Icon className={classes.sideNavIcon} />
      </ListItemIcon>
      <ListItemText className={classes.link} primary={label} />
    </ListItem>
  );
};

type NavItemProps = {
  to?: string;
  label: string | JSX.Element;
  icon: any;
  onClick?: () => void;
};

const NavItem:React.FC<NavItemProps> = ({ to, label, icon, onClick }) => {
  const classes = useStyles();

  return to
    ? (
      <Link className={classes.link} to={to}>
        <NavItemInner label={label} icon={icon} onClick={onClick} />
      </Link>
    )
    : <NavItemInner label={label} icon={icon} onClick={onClick} />;
};

export default NavItem;
