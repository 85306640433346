import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../lib/hooks/useAxios';
import downloadInvoice from '../../../../lib/utils/downloadInvoice';
import { SuncleButton, PendingBar } from '../../../../components';

const useStyles = makeStyles(() => ({
  invoiceLink: {
    opacity: 0,
    transitionProperty: 'opacity',
    transitionDuration: '0.5s',
  },
  fadeIn: {
    opacity: 1,
  },
  buttonSpacer: {
    marginLeft: 16,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
  },
  buttonRow: {
    margin: 0,
    '& > button': {
      margin: 0,
    },
    '& + $buttonRow': {
      marginTop: 8,
    },
  },
}));

type InvoiceLinkProps = {
  billingCycleId: number;
  runDate: string;
  onError: (shouldDisplayError: boolean) => void;
  fileNames: string[];
};

const InvoiceLink: React.FC<InvoiceLinkProps> = ({ billingCycleId, onError, fileNames }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const { getInstallerInvoice } = useAxios();
  const { installerId } = useParams();
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, 10);
  }, []);

  const getInvoice = async (fileName: string) => {
    if (!isDownloading && installerId) {
      try {
        setIsDownloading(true);
        const { data } = await getInstallerInvoice(installerId, billingCycleId, fileName);
        downloadInvoice(fileName, data);
        setIsDownloading(false);
      } catch (error) {
        setIsDownloading(false);
        onError(true);
      }
    }
  };

  return (
    <div className={clsx(classes.invoiceLink, fadeIn && classes.fadeIn)}>
      <ListItem>
        <div className={classes.buttonContainer}>
          {fileNames && fileNames.map((fileName) => {
            return <p key={fileName} className={classes.buttonRow}>{fileName.split('_')[1].split('.')[0]}&nbsp;&nbsp;<SuncleButton onClick={() => getInvoice(fileName)}>ダウンロード</SuncleButton></p>;
          })}
        </div>
      </ListItem>
      <Divider />
      {isDownloading && <PendingBar />}
    </div>
  );
};

export default InvoiceLink;
