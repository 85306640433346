import { Introduction } from '../../types/Introduction';
import formatDate from './formatDate';

const formatIntro = (intro: Introduction) => ({
  ...intro,
  bc_run_date: formatDate(intro.bc_run_date),
  created_at: formatDate(intro.created_at),
});

export default formatIntro;
