import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  table: {
    marginBottom: 16,
  },
  tableText: {
    whiteSpace: 'nowrap',
  },
  tableSubTitle: {
    paddingLeft: 7,
  },
  leadTable: {
    overflowX: 'scroll',
    marginTop: 16,
  },
  reason: {
    minWidth: 200,
  },
}));

type LeadTableProps = {
  isDefaultOpen?: boolean;
  data: any[];
  title?: string;
};

const LeadTable: React.FC<LeadTableProps> = ({ isDefaultOpen, data, title }) => {
  const classes = useStyles();
  const tableRows = data.map((lead, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <TableRow key={`${new Date().getTime()}${index}`}>
      {Object.keys(lead).map((col) => {
        return col === '理由詳細'
          ? <TableCell key={col} className={classes.reason}>{lead[col]}</TableCell>
          : <TableCell key={col} className={classes.tableText}>{lead[col]}</TableCell>;
      })}
    </TableRow>
  ));
  return (
    <div className={classes.leadTable}>
      {title && <Typography className={classes.tableSubTitle}>{title}</Typography>}
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {data[0]
              && Object.keys(data[0]).map((col) => {
                return <TableCell key={col} className={classes.tableText}>{col}</TableCell>;
              })}
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </div>
  );
};

export default LeadTable;
