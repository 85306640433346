/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/styles';
import Tab from '@material-ui/core/Tab';
import PageTemplate from '../../PageTemplate';
import { Sheet, Loading, TabPanel, InfoDetail } from '../../../components';
import IntroductionDetails from '../components/IntroductionDetails/IntroductionDetails';
import ApprovalForm from '../components/ApprovalForm/ApprovalForm';
import useIntroduction from '../../../lib/hooks/useIntroduction';
import CommentWidget from '../components/Comment/CommentWidget';
import IntroductionEdit from '../components/IntroductionEdit';
import useInstallers from '../../../lib/hooks/useInstallers';
import RoleContext from '../../../lib/context/RoleContext';
import { colors } from '../../../lib/styles';

const useStyles = makeStyles(() => ({
  AppBar: {
    backgroundColor: 'lavender',
    color: colors.textColor,
    border: `1px solid ${colors.borderColor}`,
  },
  Tab: {
    fontWeight: 'bold',
  },
}));

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const IntroductionShow: React.FC = () => {
  const { state: { role: currentRole } } = useContext(RoleContext);
  const { introId } = useParams();
  const { isLoading, intro } = useIntroduction(introId);
  const { isLoading: installersLoading, installersForIntro } = useInstallers(intro);
  const [inactiveInstallers, setInactiveInstallers] = useState();
  const [value, setValue] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    if (installersForIntro) {
      setInactiveInstallers(installersForIntro.filter((installer) => !installer.active));
    }
  }, [installersForIntro]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <PageTemplate pageTitle="Introduction Details">
      {isLoading && <Loading shouldLoad={isLoading} />}
      {!isLoading && (
        <Sheet>
          <AppBar position="static" className={classes.AppBar} elevation={0}>
            <Tabs
              value={value}
              onChange={handleChange}
            >
              <Tab className={classes.Tab} label="Approval" {...a11yProps(0)} />
              <Tab className={classes.Tab} label="Details" {...a11yProps(1)} />
              { (currentRole === 'admin' || currentRole === 'staff') && <Tab className={classes.Tab} label="Edit" {...a11yProps(2)} />}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <ApprovalForm intro={intro} inactiveInstallers={inactiveInstallers} />
            <InfoDetail label="Introduction ID:" data={intro ? intro.intro_id : ''} />
            <InfoDetail
              label="Name:"
              data={`${intro ? intro.family_name : ''} ${intro ? intro.given_name : ''} - ${intro ? intro.family_name_kana : ''} ${intro ? intro.given_name_kana : ''}`}
            />
            <InfoDetail
              label="Address:"
              data={`${intro ? intro.postcode : ''} ${intro ? intro.addr_l1 : ''}${intro ? intro.addr_l2 : ''}`}
            />
            <InfoDetail label="Email:" data={intro ? intro.email : ''} />
            <InfoDetail label="Phone:" data={intro ? intro.phone : ''} />
            { introId && <CommentWidget introId={introId} /> }
          </TabPanel>
          <TabPanel value={value} index={1}>
            {intro && <IntroductionDetails intro={intro} isLoading={installersLoading} installersForIntro={installersForIntro} />}
          </TabPanel>
          { (currentRole === 'admin' || currentRole === 'staff') && (
          <TabPanel value={value} index={2}>
            {intro && introId && <IntroductionEdit intro={intro} introId={introId} /> }
          </TabPanel>
          ) }
        </Sheet>
      )}
    </PageTemplate>
  );
};

export default IntroductionShow;
