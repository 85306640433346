import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PageTemplate from '../../PageTemplate';
import { Sheet, EditButton, Loading, InfoDetail, PendingSpinner } from '../../../components';
import useRefund from '../../../lib/hooks/useRefund';
import useInstaller from '../../../lib/hooks/useInstaller';
import useIntroduction from '../../../lib/hooks/useIntroduction';
import formatDate from '../../../lib/utils/formatDate';
import calcRefundDueDate from '../../../lib/utils/calcRefundDueDate';

const RefundsShow: React.FC = () => {
  const { refundId } = useParams();
  const { isLoading, refund } = useRefund(refundId);
  const { installer } = useInstaller(refund && refund.installer_id);
  const { intro } = useIntroduction(refund && refund.intro_id);

  return (
    <PageTemplate pageTitle="Refund Details">
      {isLoading && <Loading shouldLoad={isLoading} />}
      {!isLoading && (
        <Sheet>
          <div>
            <EditButton to={`/refunds/${refundId}/edit/`} />
          </div>
          <InfoDetail
            label="Refund Id:"
            data={refund ? refund.refund_id : ''}
          />
          <InfoDetail
            label="Lead Id:"
            data={refund ? refund.lead_id : ''}
          />
          <InfoDetail
            label="Introduction Id:"
          >
            {intro
              ? <Link to={`/introductions/${intro.intro_id}`}>{intro.intro_id}</Link>
              : <PendingSpinner />}
          </InfoDetail>
          <InfoDetail
            label="Installer:"
          >
            {installer
              ? <Link to={`/installers/${installer.installer_id}`}>{installer.companyName}</Link>
              : <PendingSpinner />}
          </InfoDetail>
          <InfoDetail
            label="Created at:"
            data={refund ? formatDate(refund.created_at) : ''}
          />
          <InfoDetail
            label="Due by:"
            data={refund ? calcRefundDueDate(refund.created_at) : ''}
          />
          <InfoDetail
            label="Closed at:"
            data={refund ? formatDate(refund.closed_at) : ''}
          />
          <InfoDetail
            label="Billing cycle:"
            data={refund ? refund.bc_id && formatDate(refund.bc_run_date) : ''}
          />
          <InfoDetail
            label="Status:"
            data={refund ? refund.status_text : ''}
          />
          <InfoDetail
            label="Reason:"
            data={refund ? refund.reason : ''}
          />
          <InfoDetail
            label="Reason Details:"
            note="***Installer reason for making the claim***"
            data={refund ? refund.reason_details : ''}
          />
          <InfoDetail
            label="Status Details:"
            note="***Explanation sent to the installer***"
            data={refund ? refund.status_details : ''}
          />
          <InfoDetail
            label="Notes:"
            note="***Internal use only***"
            data={refund ? refund.note : ''}
          />
        </Sheet>
      )}
    </PageTemplate>
  );
};

export default RefundsShow;
