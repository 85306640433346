import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  introDetail: {
    marginTop: 14,
    maxWidth: 565,
    minHeight: 30,
    border: '1px solid lightgray',
    '& + $introDetail': {
      borderTop: 'none',
      marginTop: 0,
    },
  },
  introDetailInner: {
    display: 'table-row',
    alignItems: 'center',
  },
  label: {
    minWidth: 140,
    maxWidth: 140,
    marginRight: 24,
    marginBottom: 0,
    marginTop: 0,
    fontSize: 14,
    fontWeight: 'bold',
    borderRight: '1px solid lightgray',
    backgroundColor: 'lavender',
    padding: 8,
    display: 'table-cell',
    verticalAlign: 'top',
  },
  data: {
    marginBottom: 0,
    marginTop: 0,
    fontSize: 14,
    padding: 8,
    display: 'table-cell',
  },
  image: {
    marginTop: 8,
    padding: 8,
  },
}));

type InfoDetailProps = {
  label?: string;
  data?: string | number | null;
  externalLink?: string;
  imageName?: string;
  note?: string;
};

const InfoDetail: React.FC<InfoDetailProps> = ({ label, data, children, imageName, note, externalLink }) => {
  const classes = useStyles();
  return (
    <div className={classes.introDetail}>
      <div className={classes.introDetailInner}>
        <p className={classes.label}>{label}</p>
        {(data || note) && (
        <p className={classes.data}>
          {note && <><Typography variant="overline">{note}</Typography><br /></>}
          {data}
        </p>
        )}
        {externalLink && <p className={classes.data}><a href={externalLink} target="_blank" rel="noopener noreferrer">{externalLink}</a></p>}
        {imageName && <img className={classes.image} src={`${process.env.REACT_APP_IMAGE_URL}${imageName}?alt=media`} />}
        {children && <div className={classes.data}>{children}</div>}
      </div>
    </div>
  );
};

export default InfoDetail;
