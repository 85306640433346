import { useEffect, useState } from 'react';
import useAxios from './useAxios';

const useNextBillingCycleCost = (installerId: string | undefined | null) => {
  const { getNextBillingCycleCost } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [costData, setCostData] = useState();

  useEffect(() => {
    const fetchLead = async () => {
      if (installerId) {
        setIsLoading(true);
        const { data: fetchedCostData } = await getNextBillingCycleCost(installerId);
        setIsLoading(false);
        setCostData(fetchedCostData);
      }
    };

    fetchLead();
  }, [installerId]);

  return {
    isLoading,
    costData,
  };
};

export default useNextBillingCycleCost;
