import React from 'react';
import Typography from '@material-ui/core/Typography';
import { PendingBar, InfoDetail } from '../../../components';
import { Installer } from '../../../types/Installer';
import useNextBillingCycleLeads from '../../../lib/hooks/useNextBillingLeads';
import useNextBillingCycleRefunds from '../../../lib/hooks/useNextBillingRefunds';
import useNextBillingCycleCost from '../../../lib/hooks/useNextBillingCost';
import LeadTable from './LeadTable';

type InstallerDetailsProps = {
  installerId: string;
  installer: Installer;
};

const InstallerDetails: React.FC<InstallerDetailsProps> = ({ installerId, installer }) => {
  const { isLoading: leadsLoading, leadData } = useNextBillingCycleLeads(installerId);
  const { isLoading: refundsLoading, refundData } = useNextBillingCycleRefunds(installerId);
  const { isLoading: costLoading, costData } = useNextBillingCycleCost(installerId);

  return (
    <div>
      <br />
      <Typography>Leads</Typography>
      { !leadsLoading && leadData && leadData.length > 0 && (
        <LeadTable data={leadData} />
      )}
      { !leadsLoading && leadData && leadData.length === 0 && <Typography>Installer has no leads</Typography>}
      { leadsLoading && <PendingBar /> }
      <br />
      <Typography>Refunds</Typography>
      { !refundsLoading && refundData && refundData.length > 0 && (
        <LeadTable data={refundData} />
      )}
      { !refundsLoading && refundData && refundData.length === 0 && <Typography>Installer has no refunds</Typography>}
      { refundsLoading && <PendingBar /> }
      { !costLoading && costData && (
        <>
          <br />
          <Typography>Charges</Typography>
          <InfoDetail label="Sales:" data={costData.sales || '0'} />
          <InfoDetail label="Returns:" data={costData.returns || '0'} />
          <InfoDetail label="Subtotal:" data={costData.subtotal || '0'} />
          <InfoDetail label="Tax:" data={costData.tax || '0'} />
          <InfoDetail label="Total:" data={costData.total || '0'} />
        </>
      )}
    </div>
  );
};

export default InstallerDetails;
