import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import { useAuth0 } from '../../../../react-auth0-wrapper';
import useAxios from '../../../../lib/hooks/useAxios';
import { PendingBar } from '../../../../components';

const useStyles = makeStyles(() => ({
  lightText: {
    fontWeight: 400,
    fontSize: '0.8em',
  },
  controlLink: {
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    outline: 'inherit',
    cursor: 'pointer',
    color: '#666666',
    textDecoration: 'underline',
  },
  createdTime: {
    fontWeight: 400,
    fontSize: '0.75em',
  },
  editedTime: {
    fontWeight: 400,
    fontSize: '0.75em',
    fontStyle: 'italic',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'row',
  },
  mgRight: {
    marginRight: 16,
  },
  mgTop: {
    marginTop: 8,
  },
  padLeft: {
    paddingLeft: 24,
  },
  grow: {
    flexGrow: 1,
  },
  danger: {
    backgroundColor: 'red',
  },
}));

type CommentProps = {
  comment: string;
  createdAt: Date;
  noteID: number;
  updatedAt?: Date | null;
  user: string;
};

const dateOptions = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };

const Comment:React.FC<CommentProps> = ({ comment, user, createdAt, updatedAt, noteID }) => {
  const classes = useStyles();
  const { updateNote } = useAxios();
  const { user: userContext } = useAuth0();
  const [value, setValue] = useState<string>(comment || '');
  const [editValue, setEditValue] = useState<string>(comment || '');
  const [editTime, setEditTime] = useState();
  const [editing, setEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (updatedAt) {
      setEditTime(new Date(updatedAt));
    }
  }, [updatedAt]);

  const handleChange = (event: any) => {
    setEditValue(event.target.value);
  };

  const confirmDelete = async (confirm: boolean) => {
    if (confirm) {
      // send deleted
      setIsLoading(true);
      const response = await updateNote({
        comment: '[deleted]',
      }, noteID);
      if (response.status === 200) {
        setValue('[deleted]');
        setEditValue('[deleted]');
        setEditTime(new Date(response.data.updated_at));
      }
      setIsLoading(false);
      // on success update value
    }
    setIsDeleting(false);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    const response = await updateNote({
      comment: editValue,
    }, noteID);
    if (response.status === 200) {
      setValue(editValue);
      setEditTime(new Date(response.data.updated_at));
      setEditing(false);
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    setEditValue(value);
    setEditing(false);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleDelete = () => {
    setIsDeleting(true);
  };

  return (
    <div>
      <div className={`${classes.flexRow} ${classes.mgTop}`}>
        <Typography className={classes.mgRight}>
          <span className={classes.lightText}>{user}</span> - {editTime && <span className={classes.editedTime}>{`${editTime.toLocaleString('JA', dateOptions)} (edited)`}</span>}
          {!editTime && <span className={classes.createdTime}>{(new Date(createdAt) || new Date()).toLocaleString('JA', dateOptions)}</span>}
        </Typography>
      </div>
      { isLoading && <PendingBar />}
      { !isLoading && editing && (
        <form onSubmit={(e) => e.preventDefault()}>
          <FormControl className={`${classes.flexRow} ${classes.mgTop}`}>
            <Input className={`${classes.grow} ${classes.mgRight}`} value={editValue} onChange={handleChange} />
            <Button type="submit" variant="contained" color="primary" onClick={handleUpdate}>Update</Button>
            <Button variant="outlined" color="primary" onClick={handleCancel}>Cancel</Button>
          </FormControl>
        </form>
      )}
      { !isLoading && !editing && <Typography className={classes.mgTop}>{ value }</Typography>}
      <div className={classes.flexRow}>
        {userContext.name === user && <p className={classes.lightText}><button type="button" className={classes.controlLink} onClick={handleEdit}>Edit</button> - <button type="button" className={classes.controlLink} onClick={handleDelete}>Delete</button></p>}
        {isDeleting && (
          <div className={classes.flexRow}>
            <Typography className={`${classes.padLeft} ${classes.mgRight} ${classes.lightText}`}>Are you sure?</Typography>
            <Button className={`${classes.danger} ${classes.mgRight}`} variant="outlined" onClick={() => confirmDelete(true)}>Yes</Button>
            <Button variant="outlined" color="primary" onClick={() => confirmDelete(false)}>No</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
