import { useEffect, useState } from 'react';
import useAxios from './useAxios';

const useNextBillingCycleRefunds = (installerId: string | undefined | null) => {
  const { getNextBillingCycleRefunds } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [refundData, setRefundData] = useState();

  useEffect(() => {
    const fetchRefund = async () => {
      if (installerId) {
        setIsLoading(true);
        const { data: fetchedRefundData } = await getNextBillingCycleRefunds(installerId);
        setIsLoading(false);
        setRefundData(fetchedRefundData);
      }
    };

    fetchRefund();
  }, [installerId]);

  return {
    isLoading,
    refundData,
  };
};

export default useNextBillingCycleRefunds;
