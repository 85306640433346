import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from './logo.svg';

const useStyles = makeStyles((theme) => ({
  headerLogo: {
    display: 'block',
    background: `url(${logo}) 0 0/100% no-repeat`,
    backgroundSize: 'contain',
    width: 140,
    height: 34,
  },
  title: {
    color: '#5d2f91',
    marginLeft: 14,
    fontWeight: 600,
    marginTop: 12,
  },
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const LogoWithText: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.logoWrap}>
      <div className={classes.headerLogo} />
      <Typography component="p" variant="h5" noWrap className={classes.title}>
        Admin
      </Typography>
    </div>
  );
};

export default LogoWithText;
