import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import SERVICE_AREAS from '../../../lib/utils/serviceAreas';
import { ServiceArea } from '../../../types/ServiceArea';

const useStyles = makeStyles(() => ({
  spaceInput: {
    marginLeft: 16,
  },
  serviceAreaField: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  serviceAreaFieldWrap: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  select: {
    flexShrink: 0,
    minWidth: 120,
  },
  numberSelect: {
    maxWidth: 80,
    flexShrink: 0,
  },
  removeButton: {
    color: 'red',
  },
}));

type FormInputNames = 'name' | 'maxLeads' | 'installation' | 'ppa';

type ServiceAreaFieldProps = {
  serviceArea: ServiceArea;
  onChange: (data: ServiceArea) => void;
  onRemove: () => void;
};

const ServiceAreaField:React.FC<ServiceAreaFieldProps> = ({ serviceArea, onChange, onRemove }) => {
  const classes = useStyles();
  const [values, setValues] = useState({ ...serviceArea });

  const handleCheckboxChange = (name: FormInputNames) => (event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: any; checked?: boolean | string; }>) => {
    const inputValue = event.target.checked === true;
    const newValues = { ...values, [name]: inputValue };
    onChange(newValues);
    setValues(newValues);
  };

  const handleChange = (name: FormInputNames) => (event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown; checked?: boolean | string; }>) => {
    const newValues = { ...values, [name]: event.target.value };
    onChange(newValues);
    setValues(newValues);
  };

  return (
    <div className={classes.serviceAreaField}>
      <div className={classes.serviceAreaFieldWrap}>
        <FormControl>
          <InputLabel>Prefecture</InputLabel>
          <Select
            onChange={handleChange('name')}
            name="name"
            value={values.name}
            className={classes.select}
          >
            <MenuItem />
            {SERVICE_AREAS.map((area) => <MenuItem value={area.value} key={area.value}>{area.label}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl className={classes.spaceInput}>
          <InputLabel>Max Leads</InputLabel>
          <Input
            name="maxLeads"
            value={values ? values.maxLeads : ''}
            onChange={handleChange('maxLeads')}
            className={classes.numberSelect}
          />
        </FormControl>
        <FormControl className={classes.spaceInput}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={Boolean(values.installation)}
                onChange={handleCheckboxChange('installation')}
                color="primary"
              />
          )}
            label="Installation"
          />
        </FormControl>
        <FormControl className={classes.spaceInput}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={Boolean(values.ppa)}
                onChange={handleCheckboxChange('ppa')}
                color="primary"
              />
          )}
            label="PPA"
          />
        </FormControl>
      </div>
      <Button className={classes.removeButton} onClick={onRemove}><DeleteIcon />&nbsp;Remove</Button>
    </div>
  );
};

export default ServiceAreaField;
