import React, { createContext, useReducer } from 'react';
import { Staff } from '../../types/Staff';

type State = {
  staff: Staff[];
};

type Action = {
  type: 'set',
  payload: {
    staff: Staff[];
  }
};

type ContextArgs = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const initialState: State = { staff: [] };

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'set':
      return { staff: action.payload.staff };
    default:
      return state;
  }
}

const StaffContext = createContext({} as ContextArgs);

export const StaffContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  StaffContext.displayName = 'StaffContext';
  return (
    <StaffContext.Provider value={value}>{children}</StaffContext.Provider>
  );
};

export default StaffContext;
