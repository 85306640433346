import React, { createContext, useReducer } from 'react';
import { BillingCycle } from '../../types/BillingCycle';

type BillingCycleContextState = {
  billingCycles: BillingCycle[];
};

type BillingCycleContextAction = {
  type: 'set',
  payload: {
    billingCycles: BillingCycle[];
  }
};

type BillingCycleContextArgs = {
  state: BillingCycleContextState;
  dispatch: React.Dispatch<BillingCycleContextAction>;
};

const initialState: BillingCycleContextState = { billingCycles: [] };

function reducer(state: BillingCycleContextState, action: BillingCycleContextAction) {
  switch (action.type) {
    case 'set':
      return { billingCycles: action.payload.billingCycles };
    default:
      return state;
  }
}

const BillingCycleContext = createContext({} as BillingCycleContextArgs);

export const BillingCycleContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  BillingCycleContext.displayName = 'BillingCycleContext';
  return (
    <BillingCycleContext.Provider value={value}>{children}</BillingCycleContext.Provider>
  );
};

export default BillingCycleContext;
