import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PageTemplate from '../PageTemplate';
import { Sheet } from '../../components';

const useStyles = makeStyles((theme) => ({
  dashboardIcon: {
    marginRight: '8px;',
  },
  featureTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  titleLink: {
    textDecoration: 'none',
  },
}));

const Reports:React.FC = () => {
  const classes = useStyles();

  return (
    <PageTemplate pageTitle="Reports">
      <Sheet>
        <p className={classes.featureTitle}>
          <Link to="/reports/billing-cycle-summaries" className={classes.titleLink}>
            <Typography variant="h6" component="span">Billing Cycle Summaries</Typography>
          </Link>
        </p>
        <p className={classes.featureTitle}>
          <Link to="/reports/customer-requests" className={classes.titleLink}>
            <Typography variant="h6" component="span">Customer Requests</Typography>
          </Link>
        </p>
      </Sheet>
    </PageTemplate>
  );
};

export default Reports;
