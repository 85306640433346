import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { SuncleButton, PaginatedTable, PendingBar, CreateButton } from '../../../components';
import LeadsTableContext from './LeadsTableContext';
import useAxios from '../../../lib/hooks/useAxios';
import { Lead } from '../../../types/Lead';
import { Installer } from '../../../types/Installer';

const useStyles = makeStyles(() => ({
  tableButton: {
    margin: 0,
  },
}));

// type LeadsState = {
//   filter: string;
//   billingCycle: string;
// };

type InstallerLeadsProps = {
  installerId: string;
  installer: Installer
};

const InstallersShow: React.FC<InstallerLeadsProps> = ({ installerId, installer }) => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const { getInstallerLeads } = useAxios();
  const [useFilteredLeads, setUseFilteredLeads] = useState(false);
  const [isLoadingLeads, setIsLoadingLeads] = useState(false);
  const [noLeads, setNoLeads] = useState(false);
  const [filteredLeads, setFilteredLeads] = useState<Lead[]>([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const classes = useStyles();

  // const [values, setValues] = useState<LeadsState>({
  //   filter: '',
  //   billingCycle: 'current',
  // });

  useEffect(() => {
    // Change the count used for pagination, when filtering intros
    if (useFilteredLeads) {
      setTotalLeads(filteredLeads.length);
    } else {
      setTotalLeads(leads.length);
    }
  }, [filteredLeads, leads, useFilteredLeads]);

  useEffect(() => {
    // Need to define functions for async operations inside useEffect
    const fetchLeads = async () => {
      if (installerId) {
        setIsLoadingLeads(true);
        const response = await getInstallerLeads(installerId);
        // TODO: handle no leads
        setLeads(response.data);
        setIsLoadingLeads(false);
        !response.data.length ? setNoLeads(true) : setNoLeads(false);
      }
    };

    fetchLeads();
  }, [installerId]);

  // TODO: Add filter/search to installer leads
  // const handleBillingCycleChange = async (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => {
  //   if (event.target.value !== values.billingCycle && installerId) {
  //     setValues({ ...values, billingCycle: event.target.value });
  //     // If changing billing cycles:
  //     // 1. Clear out the introduction data and reset to first page of table
  //     setTotalLeads(0);
  //     setFilteredLeads([]);
  //     dispatch({ type: 'goTo', payload: { nextPage: 0 } });
  //     history.push(`/installers${installerId}`);
  //     // 2. Set loading status and clear no intro message
  //     setIsLoadingLeads(true);
  //     setNoLeads(false);
  //     // 3. Get and set intros for the billing cycle
  //     const response = await getInstallerLeads(installerId, event.target.value);
  //     setIsLoadingLeads(false);
  //     setLeads(response.data);
  //     setTotalLeads(response.data.length);
  //     if (!response.data.length) setNoLeads(true);
  //   }
  // };

  // const filterLeads = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value !== '') {
  //     setUseFilteredLeads(true);
  //     // Reset to first page of table
  //     dispatch({ type: 'goTo', payload: { nextPage: 0 } });
  //     history.push(`/installers${installerId}`);
  //     setFilteredLeads(
  //       leads.filter((lead: Lead) => JSON.stringify(Object.values(lead)).includes(event.target.value)),
  //     );
  //   } else {
  //     setUseFilteredLeads(false);
  //     setFilteredLeads([]);
  //   }
  //   setValues({ ...values, filter: event.target.value });
  // };

  return (

    <>
      <PaginatedTable
        columns={[
          {
            Header: 'Lead ID',
            accessor: 'lead_id',
          },
          {
            Header: 'Intro ID',
            accessor: 'intro_id',
            Cell: ({ cell }: any) => (
              <Link to={`/introductions/${cell.value}`}>{cell.value}</Link>
            ),
          },
          {
            Header: 'Customer Name',
            disableSortBy: true,
            Cell: ({ row }: any) => (`${row.original.family_name} ${row.original.given_name}`),
          },
          {
            Header: 'Customer Email',
            accessor: 'email',
          },
          {
            Header: 'Refund',
            disableSortBy: true,
            Cell: ({ row }: any) => (
              row.original.refund_id
                ? <Link to={`/refunds/${row.original.refund_id}`}><SuncleButton className={classes.tableButton}>VIEW</SuncleButton></Link>
                : <CreateButton to={`/refunds/new?leadId=${row.original.lead_id}`} className={classes.tableButton} />
            ),
          },
        ]}
        data={useFilteredLeads ? filteredLeads : leads}
        totalRows={totalLeads}
        url={`installers/${installerId}`}
        context={LeadsTableContext}
      />
      {isLoadingLeads && <PendingBar />}
      {noLeads && (
      <Typography style={{ marginTop: 16 }}>
        There are currently no leads for this installer
      </Typography>
      )}
    </>
  );
};

export default InstallersShow;
