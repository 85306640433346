import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import { useAuth0 } from '../../../../react-auth0-wrapper';
import useAxios from '../../../../lib/hooks/useAxios';
import { PendingBar } from '../../../../components';

import Comment from './Comment';

const useStyles = makeStyles(() => ({
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'row',
  },
  mgRight: {
    marginRight: 16,
  },
  mgTop: {
    marginTop: 8,
  },
}));

type CommentWidgetProps = {
  introId: string;
};

const CommentWidget:React.FC<CommentWidgetProps> = ({ introId }) => {
  const classes = useStyles();
  const { getNotes, createNote } = useAxios();
  const { user: userContext } = useAuth0();

  const unmounted = useRef(false);
  const [notes, setNotes] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newNote, setNewNote] = useState<string>('');

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const response = await getNotes(introId);
      if (!unmounted.current) {
        if (response.status === 200) {
          setNotes(response.data);
        }
      }
      setIsLoading(false);
    };
    if (introId) {
      fetch();
    }
    return () => { unmounted.current = true; };
  }, [introId]);

  const submitNewNote = async () => {
    const body = {
      introId,
      user: userContext.name,
      comment: newNote,
    };
    setIsLoading(true);
    const response = await createNote(body);
    if (response.status === 200) {
      setNotes([response.data, ...notes]);
      setNewNote('');
    }
    setIsLoading(false);
  };

  const handleInput = (event: any) => {
    setNewNote(event.target.value);
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <FormControl className={`${classes.flexRow} ${classes.mgTop}`}>
          <InputLabel>New Note</InputLabel>
          <Input className={classes.mgRight} fullWidth={true} value={newNote} onChange={handleInput} />
          <Button type="submit" color="primary" variant="contained" startIcon={<SendIcon />} onClick={submitNewNote}>Submit</Button>
        </FormControl>
      </form>
      {isLoading && <PendingBar className={classes.mgTop} />}
      {notes.map((note) => (
        <Comment
          noteID={note.intro_note_id}
          user={note.user_name}
          comment={note.comment}
          createdAt={note.created_at}
          updatedAt={note.updated_at}
          key={note.intro_note_id}
        />
      ))}
    </div>
  );
};

export default CommentWidget;
