import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import formatDate from '../../lib/utils/formatDate';
import useBillingCycles from '../../lib/hooks/useBillingCycles';

const useStyles = makeStyles(() => ({
  controlsRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  select: {
    flexShrink: 0,
    marginRight: 16,
    minWidth: 120,
  },
}));

type TableSearchFilterProps = {
  onBillingCycleChange: (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => void;
  onSearchFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  values: {
    filter: string;
    billingCycle: string;
  }
};

const TableSearchFilter:React.FC<TableSearchFilterProps> = ({ onBillingCycleChange, onSearchFilterChange, values, children }) => {
  const classes = useStyles();
  const { isLoading, billingCycles } = useBillingCycles();

  return (
    <div className={classes.controlsRow}>
      <FormControl className={classes.select}>
        <InputLabel htmlFor="billing-cycle-select">Billing Cycle</InputLabel>
        <Select
          value={values.billingCycle}
          onChange={onBillingCycleChange}
          id="billing-cycle-select"
          name="billingCycle"
          disabled={isLoading}
        >
          <MenuItem value="current">CURRENT</MenuItem>
          {billingCycles.map(
            (bc) => (
              <MenuItem key={bc.bc_id} value={bc.bc_id}>
                {formatDate(bc.run_date)}
              </MenuItem>
            ),
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="refunds-filter">Search Filter</InputLabel>
        <Input
          id="refunds-filter"
          onChange={onSearchFilterChange}
          value={values.filter}
        />
      </FormControl>
      {children}
    </div>
  );
};

export default TableSearchFilter;
