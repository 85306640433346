import { useEffect, useState, useContext } from 'react';
import useAxios from './useAxios';
import RefundContext from '../context/RefundsContext';
import { Refund } from '../../types/Refund';

const useRefund = (refundId: string | undefined) => {
  const { state: refunds } = useContext(RefundContext);
  const { getRefund } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [refund, setRefund] = useState<Refund>();

  useEffect(() => {
    const fetchRefund = async () => {
      if (refundId) {
        const allRefunds = Object.keys(refunds).reduce((acc: Refund[], bc) => [...acc, ...refunds[bc]], []);
        const currentRefund = allRefunds.find((r: Refund) => Number(refundId) === Number(r.refund_id));
        // Set the refund found in context for initial page load
        if (currentRefund) setRefund(currentRefund);
        // isLoading is only for initial page load, if there is no refund in context
        if (!currentRefund) setIsLoading(true);
        // fetch and set the refund from the server, in case the data is stale
        const { data: fetchedRefund } = await getRefund(Number(refundId));
        setRefund(fetchedRefund);
        if (!currentRefund) setIsLoading(false);
      }
    };

    fetchRefund();
  }, [refundId]);

  return {
    isLoading,
    refund,
  };
};

export default useRefund;
