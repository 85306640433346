import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  spinner: {
    color: '#e59924',
    marginLeft: 4,
  },
}));

const PendingSpinner:React.FC = () => {
  const classes = useStyles();

  return <CircularProgress size={18} thickness={6} className={classes.spinner} />;
};

export default PendingSpinner;
