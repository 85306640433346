import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PageTemplate from '../../PageTemplate';
import { Sheet, Loading } from '../../../components';
import useRefund from '../../../lib/hooks/useRefund';
import useAxios from '../../../lib/hooks/useAxios';
import { RefundFormValues } from '../../../types/Refund';
import RefundsForm from '../components/RefundsForm/RefundsForm';

const RefundsEdit: React.FC = () => {
  const { refundId } = useParams();
  const { isLoading, refund } = useRefund(refundId);
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const { updateRefund } = useAxios();

  const submitRefund = async (values: RefundFormValues) => {
    if (values && refundId) {
      if (submitMessage) {
        setSubmitMessage(null);
      }

      const response = await updateRefund(values, refundId);
      if (response.status === 200) {
        setSubmitMessage('Successfully updated the refund!');
        return true;
      }
      setSubmitMessage(
        'Something went wrong, please try to submit again.',
      );
      return false;
    }
    return null;
  };

  return (
    <PageTemplate pageTitle="Edit Refund">
      {isLoading && <Loading shouldLoad={isLoading} />}
      {!isLoading && refund && (
      <Sheet>
        <RefundsForm
          refund={refund}
          submitRefund={submitRefund}
          submitMessage={submitMessage}
          setSubmitMessage={setSubmitMessage}
          newRefund={false}
          billingCycleId={refund.bc_id}
        />
      </Sheet>
      )}
    </PageTemplate>
  );
};

export default RefundsEdit;
