import axios from 'axios';
import { useAuth0 } from '../../react-auth0-wrapper';
import { Introduction } from '../../types/Introduction';
import { RefundFormValues } from '../../types/Refund';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
});

const useAxios = () => {
  const { apiToken } = useAuth0();

  const get = async (path: string, otherHeaders?: any) => {
    if (!apiToken) {
      console.error('API token could not be found.');
      // TODO: handle case with no token
    }
    return axiosInstance.get(path, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        ...otherHeaders,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  };

  const post = async (path: string, data: any, otherHeaders?: any) => {
    if (!apiToken) {
      console.error('API token could not be found.');
      // TODO: handle case with no token
    }
    return axiosInstance.post(path, data, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        ...otherHeaders,
      },
    })
      .then((response) => response)
      .catch((response) => response);
  };

  const deleteReq = async (path: string, otherHeaders?: any) => {
    if (!apiToken) {
      // TODO: handle case with no token
    }
    return axiosInstance.delete(path, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        ...otherHeaders,
      },
    })
      .then((response) => response)
      .catch((response) => response);
  };

  const getBillingCycles = () => get('/billingCycles');

  const getRecentIntroductions = () => get('/billingCycles/current/introductions');

  const getIntrosForBillingCycle = (bcId: any) => get(`/billingCycles/${bcId}/introductions`);

  const getLeadsForBillingCycle = (bcId: any) => get(`/billingCycles/${bcId}/leads`);

  const getIntroductionCount = () => get('/introductions/stats')
    .then((response) => {
      if (response && response.status === 200) {
        return response.data.new;
      }
      return null;
    });

  const getIntroduction = (introId: number | string) => get(`/introductions/${introId}`);

  const getIntroductionLeads = (introId: number) => get(`/introductions/${introId}/leads`);

  const updateIntroduction = (data: Introduction) => post(`/introductions/${data.intro_id}`, data);

  const getDashboard = () => get('/dashboard/v2');

  const getInstallers = () => get('/installers/v2');

  type IntroApprovalParams = {
    status: number;
    additionalText: string | null;
    excludeHomeOwner: boolean
  };
  const updateIntroductionStatus = (introId: number, data: IntroApprovalParams) => post(`/introductions/${introId}/update-status`, data);

  const updateInstaller = (data: FormData, id: string) => post(`/installers/v2/${id}`, data);

  const createInstaller = (data: FormData) => post('/installers/v2/', data);

  const getRefunds = (bcId?: string) => {
    // TODO: refactor to use /billingCycles/:id/refunds in a separate method
    if (bcId && bcId !== 'current') {
      return get(`/refunds/v2?bcId=${bcId}`);
    }
    return get('/refunds/v2');
  };

  const getRefund = (refundId: number) => get(`/refunds/v2/${refundId}`);

  const getRefundForLead = (leadId: number) => get(`/leads/v2/${leadId}/refund`);

  const updateRefund = (data: RefundFormValues, id: string) => post(`/refunds/v2/${id}`, data);

  const createRefund = (data: RefundFormValues) => post('/refunds/v2', data);

  const getAllStaff = () => get('/staffV2');

  const createStaff = (data: any) => post('/staffV2/create', data);

  const deleteStaff = (data: any) => post('/staffV2/delete', data);

  const resetStaffPassword = (data: any) => post('/staffV2/resetPassword', data);

  const getUserRole = () => get('/staffV2/role');

  const getIncentives = () => get('/incentives/v2/all');

  const uploadIncentives = (data: any) => post('/incentives/v2/upload', data);
  const getLead = (id: string | number) => get(`/leads/v2/${id}`);

  const deleteLead = (leadId: number) => deleteReq(`/leads/v2/${leadId}`);

  const createLead = (data: {intro_id: number, installer_id: number}) => post('/leads/v2', data);

  const getInstallerLeads = (installerId: number | string, bcId?: number | string) => {
    // API not yet implemented
    // if (bcId && bcId !== 'current') {
    //   return get(`/installers/v2/${installerId}/leads?bcId=${bcId}`);
    // }
    return get(`/installers/v2/${installerId}/leads`);
  };

  const getInstallerBillingCycles = (installerId: number | string) => get(`/installers/v2/${installerId}/billing-cycles`);

  const getInstallerInvoiceData = (installerId: number | string, bcId: number, fileName: string) => get(`/installers/v2/${installerId}/billing-cycles/${bcId}/invoices/${fileName}?format=json`);

  const getInstallerInvoice = (installerId: number | string, bcId: number, fileName: string) => get(`/installers/v2/${installerId}/billing-cycles/${bcId}/invoices/${fileName}`);

  const getInstallerInvoiceFileNames = (installerId: number | string) => get(`/installers/v2/${installerId}/invoice-file-names`);

  const getServiceAreaByPrefecture = (prefecture: string, postcode: number | string) => {
    // Prefecture can be Kana or Romaji
    // Should url encode both the prefecture and postcode, because they can be full width
    // Prefecture and postcode are decoded by the api
    return get(`/serviceArea/${encodeURIComponent(prefecture)}/${encodeURIComponent(postcode)}`);
  };

  const getServiceAreaInstallers = (id: string | number) => get(`/serviceArea/${id}/installers`);

  const getBillingCycleSummaries = (id: string | number) => get(`/billingCycles/${id}/summaries`);
  const getCurrentBillingCycleSummary = () => get('/billingCycles/current-summary');

  const getStatusesForTable = (tableName: string) => get(`/statuses/v2/table/${tableName}`);

  // TODO: introNote Type
  const getNotes = (id: string | number) => get(`/introductions/${id}/notes`);
  const createNote = (data: any) => post('/introNotes/v2', data);
  const updateNote = (data: any, id: number) => post(`/introNotes/v2/${id}`, data);

  const getNextBillingCycleLeads = (id: string) => get(`/installers/v2/${id}/next-billing-cycle-leads`);
  const getNextBillingCycleRefunds = (id: string) => get(`/installers/v2/${id}/next-billing-cycle-refunds`);
  const getNextBillingCycleCost = (id: string) => get(`/installers/v2/${id}/next-billing-cycle-cost`);

  const getInstallerCities = () => get('/cities');

  return {
    getBillingCycles,
    getRecentIntroductions,
    getIntrosForBillingCycle,
    getLeadsForBillingCycle,
    updateIntroductionStatus,
    getIntroductionCount,
    getIntroduction,
    getIntroductionLeads,
    getAllStaff,
    createStaff,
    deleteStaff,
    resetStaffPassword,
    getUserRole,
    getDashboard,
    getInstallers,
    updateIntroduction,
    updateInstaller,
    createInstaller,
    getInstallerBillingCycles,
    getInstallerInvoiceData,
    getInstallerInvoice,
    getInstallerLeads,
    getInstallerInvoiceFileNames,
    getIncentives,
    uploadIncentives,
    getRefunds,
    getRefund,
    updateRefund,
    createRefund,
    getRefundForLead,
    getLead,
    deleteLead,
    createLead,
    getServiceAreaByPrefecture,
    getServiceAreaInstallers,
    getBillingCycleSummaries,
    getCurrentBillingCycleSummary,
    getNotes,
    createNote,
    updateNote,
    getStatusesForTable,
    getNextBillingCycleLeads,
    getNextBillingCycleRefunds,
    getNextBillingCycleCost,
    getInstallerCities,
  };
};

export default useAxios;
