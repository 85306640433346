import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Header, SideNav, Loading, AppWrapper, ProtectedRoute } from './components';
import { IntroductionContextProvider } from './lib/context/IntroductionContext';
import { InstallersContextProvider } from './lib/context/InstallersContext';
import { RefundsContextProvider } from './lib/context/RefundsContext';
import { StaffContextProvider } from './lib/context/StaffContext';
import { BillingCycleContextProvider } from './lib/context/BillingCycleContext';
import RoleContext from './lib/context/RoleContext';
import {
  BillingCycleSummariesPage,
  CustomerRequestsPage,
  DashboardPage,
  IntroductionsPage,
  IncentivesPage,
  IntroductionPage,
  InstallersPage,
  InstallerPage,
  InstallersNewPage,
  RefundsPage,
  RefundPage,
  RefundsEditPage,
  RefundsNewPage,
  StaffIndexPage,
  StaffNewPage,
  ResetPasswordPage,
  Reports } from './pages';
import useAxios from './lib/hooks/useAxios';

const AuthenticatedApp: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(window.innerWidth > 767);
  const { state: { role: currentRole }, dispatch } = useContext(RoleContext);
  const { getUserRole } = useAxios();

  useEffect(() => {
    const fetchRole = async () => {
      const { data: role } = await getUserRole();
      dispatch({ type: 'set', payload: { role: role.name } });
    };
    fetchRole();
  }, []);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <BillingCycleContextProvider>
      <StaffContextProvider>
        <InstallersContextProvider>
          <IntroductionContextProvider>
            <RefundsContextProvider>
              <Header handleDrawerOpen={handleDrawerOpen} drawerOpen={drawerOpen} />
              <SideNav handleDrawerClose={handleDrawerClose} drawerOpen={drawerOpen} />
              <Loading />
              <AppWrapper>
                <Switch>
                  <Route exact path="/introductions" component={IntroductionsPage} />
                  <Route exact path="/introductions/:introId" component={IntroductionPage} />
                  <Route exact path="/incentives" component={IncentivesPage} />
                  <Route exact path="/installers" component={InstallersPage} />
                  <ProtectedRoute exact path="/installers/new" component={InstallersNewPage} condition={currentRole === 'admin' || currentRole === 'staff'} />
                  <Route exact path="/installers/:installerId" component={InstallerPage} />
                  <Route exact path="/refunds" component={RefundsPage} />
                  <ProtectedRoute exact path="/refunds/new" component={RefundsNewPage} condition={currentRole === 'admin' || currentRole === 'staff'} />
                  <Route exact path="/refunds/:refundId" component={RefundPage} />
                  <ProtectedRoute exact path="/refunds/:refundId/edit" component={RefundsEditPage} condition={currentRole === 'admin' || currentRole === 'staff'} />
                  <Route exact path="/reports" component={Reports} />
                  <Route exact path="/reports/customer-requests" component={CustomerRequestsPage} />
                  <Route exact path="/reports/billing-cycle-summaries" component={BillingCycleSummariesPage} />
                  <ProtectedRoute exact path="/staff" component={StaffIndexPage} condition={currentRole === 'admin'} />
                  <ProtectedRoute exact path="/staff/new" component={StaffNewPage} condition={currentRole === 'admin'} />
                  <Route exact path="/profile/reset-password" component={ResetPasswordPage} />
                  <Route path="/" component={DashboardPage} />
                </Switch>
              </AppWrapper>
            </RefundsContextProvider>
          </IntroductionContextProvider>
        </InstallersContextProvider>
      </StaffContextProvider>
    </BillingCycleContextProvider>
  );
};

export default AuthenticatedApp;
