import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import PageTemplate from '../../PageTemplate';
import { Sheet } from '../../../components';

import useAxios from '../../../lib/hooks/useAxios';
import { useAuth0 } from '../../../react-auth0-wrapper';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#e59924',
    color: '#fff',
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: '#e5ab24',
    },
    '&:disabled': {
      color: '#5d2f91',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  buttonSecondary: {
    backgroundColor: '#5d2f91',
    color: '#fff',
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: '#4d2777',
    },
    '&:disabled': {
      color: '#5d2f91',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  addMargin: {
    margin: 16,
  },
}));

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth0();
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const { resetStaffPassword } = useAxios();

  const handleResetClick = () => {
    resetStaffPassword({ email: user.email }).then((response) => {
      if (response.status === 200) {
        setSubmitMessage('Password change email has been sent!');
      } else {
        setSubmitMessage('An Error occured. Please try again in a few minutes.');
      }
    });
  };

  return (
    <PageTemplate pageTitle="Reset Password">
      <Sheet>
        <div>
          <Button onClick={() => handleResetClick()} className={classes.buttonSecondary}>
            Reset Password
          </Button>
        </div>
        <Typography className={classes.addMargin}>{submitMessage}</Typography>
      </Sheet>
    </PageTemplate>
  );
};

export default ResetPassword;
