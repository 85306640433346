import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import useBillingCycles from '../../../lib/hooks/useBillingCycles';
import { Sheet, PendingBar } from '../../../components';
import PageTemplate from '../../PageTemplate';
import UserRequestList from '../components/CustomerRequestDetails';

const CustomerRequests: React.FC = () => {
  const { isLoading: billingCyclesLoading, billingCycles } = useBillingCycles();

  return (
    <PageTemplate pageTitle="Reports">
      <Sheet>
        <Typography variant="h5">Customer Requests</Typography>
        <List>
          {!billingCyclesLoading && billingCycles.map((bc) => (
            <UserRequestList key={bc.bc_id} billingCycleId={bc.bc_id} runDate={bc.run_date} />
          ))}
        </List>
        {billingCyclesLoading && <PendingBar />}
      </Sheet>
    </PageTemplate>
  );
};

export default CustomerRequests;
