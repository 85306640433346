import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Input from '@material-ui/core/Input';
import { TextInput, SuncleButton, PendingBar, CancelButton } from '../../../components';
import { Installer, InstallerFormData } from '../../../types/Installer';
import ServiceAreaField from './ServiceAreaField';
import { ServiceArea } from '../../../types/ServiceArea';
import { ServiceAreas } from '../../../types/ServiceAreas';
import useAxios from '../../../lib/hooks/useAxios';

type FormInputNames = 'companyName' | 'active' | 'address' | 'phone' | 'familyName' | 'givenName' | 'familyNameFuri' | 'givenNameFuri' | 'emailForAdmin' | 'emailForLeads' | 'marketingText' | 'file' | 'city_id';

const useStyles = makeStyles(() => ({
  FormWrap: {
    maxWidth: 565,
  },
  formInput: {
    display: 'block',
    maxWidth: 565,
    marginTop: 8,
  },
  formRow: {
    display: 'flex',
  },
  nameField: {
    marginLeft: 16,
  },
  switchLabel: {
    marginLeft: 0,
  },
  addServiceButton: {
    marginTop: 16,
  },
  imageContainer: {
    marginBottom: 8,
  },
  select: {
    flexShrink: 0,
    marginRight: 18,
    minWidth: 160,
    maxWidth: 160,
  },
}));

const copyInstaller = (installer: Installer) => {
  if (installer) {
    const copiedInstaller = {
      ...installer,
      areaStat: { ...installer.areaStat },
      serviceArea: [...installer.serviceArea],
    };

    delete copiedInstaller.company_name;
    delete copiedInstaller.contact_name;
    delete copiedInstaller.contact_email;
    delete copiedInstaller.contact_lead_email;
    delete copiedInstaller.installer_id;
    delete copiedInstaller.alt_id;

    return copiedInstaller;
  }
  return installer;
};

const getAreaStat = (serviceAreas: ServiceArea[]) => serviceAreas.reduce((acc: { [k in ServiceAreas]?: boolean }, area) => {
  if (area.name !== '') {
    acc[area.name] = true;
  }
  return acc;
}, {});

type InstallersFormProps = {
  installer: Installer;
  submitInstaller: (values: InstallerFormData) => void;
  submitMessage: string | null;
  setSubmitMessage: (message: string | null) => void;
  cancelLinkUrl: string;
};

const InstallersForm: React.FC<InstallersFormProps> = ({ installer, submitInstaller, submitMessage, setSubmitMessage, cancelLinkUrl }) => {
  const { getInstallerCities } = useAxios();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [values, setValues] = useState<InstallerFormData | undefined>();
  const [installerCities, setInstallerCities] = useState<{name_en: string, name_jp: string, city_id: number}[]>();
  const classes = useStyles();

  useEffect(() => {
    const fetchCities = async () => {
      const response = await getInstallerCities();
      if (response && response.status === 200) {
        setInstallerCities(response.data);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    if (installer) {
      setValues(copyInstaller(installer));
    }
  }, [installer]);

  const handleCheckboxChange = (name: FormInputNames) => (event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: any; checked?: boolean | string; }>) => {
    if (values) {
      const inputValue = event.target.checked === true;
      setValues({ ...values, [name]: inputValue });
    }
  };

  const handleChange = (name: FormInputNames) => (event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: any; checked?: boolean | string; files?: any }>) => {
    if (submitMessage) {
      setSubmitMessage(null);
    }
    if (values) {
      if (name === 'emailForLeads') {
        // For lead emails, need to strip commas and whitespace, and create array
        setValues({ ...values, emailForLeads: event.target.value.split(',').map((email: string) => email.trim()) });
      } else if (name === 'file') {
        const reader = new FileReader();
        reader.onloadend = () => {
          const previewImageUrl: string = typeof reader.result === 'string'
            ? reader.result
            : String.fromCharCode.apply(null, reader.result as any);
          setImagePreview(previewImageUrl);
        };
        reader.readAsDataURL(event.target.files[0]);
        setValues({ ...values, file: event.target.files[0], logo: event.target.files[0].name });
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
    }
  };

  const handleServiceAreaChange = (data: ServiceArea, index: number) => {
    if (values) {
      const newServiceAreas = [...values.serviceArea];
      newServiceAreas[index] = data;
      setValues({
        ...values,
        serviceArea: newServiceAreas,
        areaStat: getAreaStat(newServiceAreas),
      });
    }
  };

  const addServiceArea = () => {
    if (values) {
      const newServiceAreas = [...values.serviceArea];
      newServiceAreas.push({
        installation: false,
        maxLeads: 0,
        name: '',
        ppa: false,
        service: '',
      });
      setValues({ ...values, serviceArea: newServiceAreas });
    }
  };

  const removeServiceArea = (index: number) => {
    if (values) {
      const newServiceAreas = values.serviceArea.filter((area, i) => i !== index);
      setValues({
        ...values,
        serviceArea: newServiceAreas,
        areaStat: getAreaStat(newServiceAreas),
      });
    }
  };

  const handleFormSubmit = async () => {
    if (values && !formSubmitting) {
      setFormSubmitting(true);
      await submitInstaller(values);
      setFormSubmitting(false);
    }
  };

  return (
    <div className={classes.FormWrap}>
      <TextInput
        labelText="Company Name"
        name="companyName"
        value={values ? values.companyName : ''}
        onChange={handleChange('companyName')}
        fullWidth={true}
      />
      <FormControl className={classes.formInput}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={values ? values.active : false}
              onChange={handleCheckboxChange('active')}
              color="primary"
            />
            )}
          label="Active"
          labelPlacement="start"
          className={classes.switchLabel}
        />
      </FormControl>
      <FormControl className={classes.select}>
        <InputLabel htmlFor="city-select">City</InputLabel>
        <Select
          id="city-select"
          value={values ? values.city_id : ''}
          name="city_id"
          onChange={handleChange('city_id')}
          disabled={!installerCities}
        >
          <MenuItem value="">--</MenuItem>
          {installerCities && installerCities.map((city) => (
            <MenuItem key={city.city_id} value={city.city_id}>{city.name_jp}</MenuItem>
          ))}
        </Select>
        <FormHelperText>(for special area installers)</FormHelperText>
      </FormControl>
      <TextInput
        labelText="Address"
        name="address"
        value={values ? values.address : ''}
        onChange={handleChange('address')}
        fullWidth={true}
      />
      <TextInput
        labelText="Phone Number"
        name="phone"
        value={values ? values.phone : ''}
        onChange={handleChange('phone')}
      />
      <div className={classes.formRow}>
        <TextInput
          labelText="Family Name - Kanji"
          name="familyName"
          value={values ? values.familyName : ''}
          onChange={handleChange('familyName')}
          fullWidth={true}
        />
        <TextInput
          className={classes.nameField}
          labelText="Given Name - Kanji"
          name="givenName"
          value={values ? values.givenName : ''}
          onChange={handleChange('givenName')}
          fullWidth={true}
        />
      </div>
      <div className={classes.formRow}>
        <TextInput
          labelText="Family Name - Kana"
          name="familyNameFuri"
          value={values ? values.familyNameFuri : ''}
          onChange={handleChange('familyNameFuri')}
        />
        <TextInput
          className={classes.nameField}
          labelText="Given Name - Kana"
          name="givenNameFuri"
          value={values ? values.givenNameFuri : ''}
          onChange={handleChange('givenNameFuri')}
        />
      </div>
      <TextInput
        labelText="Email for Admin"
        name="emailForAdmin"
        value={values ? values.emailForAdmin : ''}
        onChange={handleChange('emailForAdmin')}
        fullWidth={true}
      />
      <TextInput
        labelText="Email for Leads"
        name="emailForLeads"
        value={values ? values.emailForLeads.join(', ') : ''}
        onChange={handleChange('emailForLeads')}
        fullWidth={true}
      />
      <p>Service Areas:</p>
      {values && values.serviceArea.map((area, index) => (
        <ServiceAreaField
          key={area.name}
          serviceArea={area}
          onChange={(data: ServiceArea) => handleServiceAreaChange(data, index)}
          onRemove={() => removeServiceArea(index)}
        />
      ))}
      <div>
        <Button
          className={classes.addServiceButton}
          onClick={addServiceArea}
          startIcon={<AddIcon />}
        >
          Add Service Area
        </Button>
      </div>
      <TextInput
        labelText="Marketing Text"
        name="marketingText"
        value={values ? values.marketingText : ''}
        onChange={handleChange('marketingText')}
        fullWidth={true}
        multiline={true}
      />
      <FormControl className={classes.formInput}>
        <p>Company Logo</p>
        <div className={classes.imageContainer}>
          { imagePreview && <img src={imagePreview} alt="" /> }
          { (installer && !imagePreview && installer.logo) && <img src={`${process.env.REACT_APP_IMAGE_URL}${installer.logo}?alt=media`} alt="" /> }
        </div>
        <Input type="file" name="file" onChange={handleChange('file')} />
      </FormControl>
      <div>
        <SuncleButton onClick={handleFormSubmit} disabled={formSubmitting}>Submit</SuncleButton>
        <CancelButton />
      </div>
      {submitMessage && <p>{submitMessage}</p>}
      {formSubmitting && <PendingBar />}
    </div>
  );
};

export default InstallersForm;
