import React, { createContext, useReducer } from 'react';
import { Refund as RefundType } from '../../types/Refund';

type RefundContextState = {
  [key: string]: RefundType[];
};

type RefundContextAction = {
  type: 'set',
  payload: {
    refunds: RefundType[];
    billingCycle: string;
  }
};

type RefundContextArgs = {
  state: RefundContextState;
  dispatch: React.Dispatch<RefundContextAction>;
};

export const initialState: RefundContextState = {};

export function reducer(state: RefundContextState, action: RefundContextAction) {
  switch (action.type) {
    case 'set':
      return { ...state, [action.payload.billingCycle]: action.payload.refunds };
    default:
      return state;
  }
}

const RefundContext = createContext({} as RefundContextArgs);

export const RefundsContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  RefundContext.displayName = 'RefundContext';
  return (
    <RefundContext.Provider value={value}>{children}</RefundContext.Provider>
  );
};

export default RefundContext;
