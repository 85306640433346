import React, { createContext, useReducer } from 'react';

type TableState = {
  currentPage: number;
};

type TableAction = {
  type: 'goTo',
  payload: {
    nextPage: number;
  }
};

type ContextArgs = {
  state: TableState;
  dispatch: React.Dispatch<TableAction>;
};

export const initialState: TableState = { currentPage: 0 };

export function reducer(state: TableState, action: TableAction) {
  switch (action.type) {
    case 'goTo':
      return { currentPage: action.payload.nextPage };
    default:
      return state;
  }
}

const LeadsTableContext = createContext({} as ContextArgs);

export const LeadsTableContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  LeadsTableContext.displayName = 'LeadsTableContext';
  return (
    <LeadsTableContext.Provider value={value}>{children}</LeadsTableContext.Provider>
  );
};

export const withLeadsTableContextProvider = (Component: React.FC) => () => (
  <LeadsTableContextProvider><Component /></LeadsTableContextProvider>
);

export default LeadsTableContext;
