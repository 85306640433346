/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/styles';
import InstallerBilling from '../components/InstallerBilling';
import InstallerDetails from '../components/InstallerDetails';
import InstallerLeads from '../components/InstallerLeads';
import InstallersEdit from '../components/InstallersEdit';
import { withLeadsTableContextProvider } from '../components/LeadsTableContext';
import PageTemplate from '../../PageTemplate';
import { Sheet, Loading, TabPanel } from '../../../components';
import useInstaller from '../../../lib/hooks/useInstaller';
import { colors } from '../../../lib/styles';
import RoleContext from '../../../lib/context/RoleContext';

const useStyles = makeStyles(() => ({
  AppBar: {
    backgroundColor: 'lavender',
    color: colors.textColor,
    border: `1px solid ${colors.borderColor}`,
  },
  Tab: {
    fontWeight: 'bold',
  },
}));

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const InstallersShow: React.FC = () => {
  const { state: { role: currentRole } } = useContext(RoleContext);
  const { installerId } = useParams();
  const { isLoading, installer } = useInstaller(installerId);
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <PageTemplate pageTitle="Installer Details">
      {isLoading && <Loading shouldLoad={isLoading} />}
      {!isLoading && (
      <Sheet>
        <AppBar position="static" className={classes.AppBar} elevation={0}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
          >
            <Tab className={classes.Tab} label="Details" {...a11yProps(0)} />
            <Tab className={classes.Tab} label="Leads" {...a11yProps(1)} />
            <Tab className={classes.Tab} label="Billing" {...a11yProps(2)} />
            { (currentRole === 'admin' || currentRole === 'staff') && <Tab className={classes.Tab} label="Edit" {...a11yProps(3)} /> }
          </Tabs>
        </AppBar>
        <TabPanel value={selectedTab} index={0}>
          { installer && installerId && <InstallerDetails installer={installer} /> }
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          { installer && installerId && <InstallerLeads installer={installer} installerId={installerId} /> }
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          { installer && installerId && <InstallerBilling installer={installer} installerId={installerId} /> }
        </TabPanel>
        { (currentRole === 'admin' || currentRole === 'staff') && (
        <TabPanel value={selectedTab} index={3}>
          { installer && installerId && <InstallersEdit installer={installer} installerId={installerId} /> }
        </TabPanel>
        )}
      </Sheet>
      )}
    </PageTemplate>
  );
};

export default withLeadsTableContextProvider(InstallersShow);
