import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import IntroductionIcon from '@material-ui/icons/PlaylistAddCheck';
import InstallerIcon from '@material-ui/icons/Group';
// import InstallerSubviewIcon from '@material-ui/icons/ViewHeadline';
import IncentivesIcon from '@material-ui/icons/MonetizationOn';
import RefundIcon from '@material-ui/icons/Receipt';
import ReportIcon from '@material-ui/icons/Assignment';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import StaffIcon from '@material-ui/icons/Person';
import ResetPasswordIcon from '@material-ui/icons/Lock';
import NavItem from './NavItem';
import { useAuth0 } from '../../react-auth0-wrapper';
import useInterval from '../../lib/hooks/useInterval';
import useAxios from '../../lib/hooks/useAxios';
import IntroductionCountContext from '../../lib/context/IntroductionCountContext';
import RoleContext from '../../lib/context/RoleContext';
import PendingSpinner from '../PendingSpinner/PendingSpinner';

const useStyles = makeStyles((theme) => ({
  logOutIcon: {
    marginTop: 'auto',
  },
  withLoading: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationIcon: {
    fontSize: 18,
    marginLeft: 4,
  },
}));

const SideNavItems: React.FC = () => {
  const { getIntroductionCount } = useAxios();
  const { state: { pendingIntroductions }, dispatch } = useContext(IntroductionCountContext);
  const { state: { role } } = useContext(RoleContext);
  const { logout } = useAuth0();
  const classes = useStyles();

  useEffect(() => {
    const fetchIntroductionCount = async () => {
      const approvalCount = await getIntroductionCount();
      dispatch({ type: 'set', payload: { pendingIntroductions: approvalCount } });
    };
    if (!pendingIntroductions) {
      fetchIntroductionCount();
    }
  }, []);

  useInterval(async () => {
    const approvalCount = await getIntroductionCount();
    dispatch({ type: 'set', payload: { pendingIntroductions: approvalCount } });
    // Update the approval count every minute
  }, 60000);

  const approvalLinkContent = () => {
    if (pendingIntroductions === null) {
      return (
        <span className={classes.withLoading}>
          Introductions
          <PendingSpinner />
        </span>
      );
    }
    if (pendingIntroductions > 0) {
      return (
        <span>
          Introductions
          <Badge badgeContent={pendingIntroductions} color="secondary">
            <NotificationsIcon className={classes.notificationIcon} />
          </Badge>
        </span>
      );
    }
    return 'Introductions';
  };

  return (
    <>
      <List>
        <NavItem to="/" label="Dashboard" icon={DashboardIcon} />
        <Divider />
        <NavItem to="/introductions" label={approvalLinkContent()} icon={IntroductionIcon} />
        <Divider />
        <NavItem to="/installers" label="Installers" icon={InstallerIcon} />
        <Divider />
        {/* <NavItem to="/installer-subview" label="Installer Subview" icon={InstallerSubviewIcon} /> */}
        {/* <Divider /> */}
        <NavItem to="/incentives" label="Incentives" icon={IncentivesIcon} />
        <Divider />
        <NavItem to="/refunds" label="Refund Claims" icon={RefundIcon} />
        <Divider />
        { role === 'admin' && (
          <>
            <NavItem to="/staff" label="Admin/Staff" icon={StaffIcon} />
            <Divider />
          </>
        )}
        <NavItem to="/reports" label="Reports" icon={ReportIcon} />
        <Divider />
        <NavItem to="/profile/reset-password" label="Reset password" icon={ResetPasswordIcon} />
        <Divider />
      </List>
      <List className={classes.logOutIcon}>
        <NavItem onClick={() => logout({ redirectTo: `${window.location.origin}/login` })} label="Log out" icon={PowerIcon} />
      </List>
    </>
  );
};

export default SideNavItems;
