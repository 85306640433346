import React, { useState, useContext } from 'react';
import PageTemplate from '../../PageTemplate';
import { Sheet } from '../../../components';
import InstallerContext from '../../../lib/context/InstallersContext';
import useAxios from '../../../lib/hooks/useAxios';
import { Installer, InstallerFormData } from '../../../types/Installer';
import InstallersForm from '../components/InstallersForm';
import formDataFromValues from '../utils/formDataFromValues';

const defaultInstaller = {
  active: false,
  address: '',
  areaStat: {},
  companyName: '',
  emailForAdmin: '',
  emailForLeads: [],
  familyName: '',
  familyNameFuri: '',
  givenName: '',
  givenNameFuri: '',
  id: '',
  logo: '',
  marketingText: '',
  phone: '',
  serviceArea: [],
  company_name: '',
  contact_name: '',
  contact_email: '',
  contact_lead_email: { emailArray: [''] },
  installer_id: 0,
  alt_id: '',
};

const InstallersNew: React.FC = () => {
  const { state: { installers }, dispatch } = useContext(InstallerContext);
  const [installer, setInstaller] = useState<Installer>(defaultInstaller);
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const { createInstaller } = useAxios();

  const submitInstaller = (values: InstallerFormData) => {
    if (values) {
      if (submitMessage) {
        setSubmitMessage(null);
      }
      const formData = formDataFromValues(values);

      return createInstaller(formData)
        .then((response) => {
          if (response.status === 200) {
            setInstaller(response.data);
            dispatch({ type: 'set', payload: { installers: [...installers, response.data] } });
            setSubmitMessage('Successfully added new installer!');
          } else {
            setSubmitMessage(
              'Something went wrong, please try to submit again.',
            );
          }
        });
    }
    return null;
  };

  return (
    <PageTemplate pageTitle="Create Installer">
      <Sheet>
        <InstallersForm
          installer={installer}
          submitInstaller={submitInstaller}
          submitMessage={submitMessage}
          setSubmitMessage={setSubmitMessage}
          cancelLinkUrl="/installers"
        />
      </Sheet>
    </PageTemplate>
  );
};

export default InstallersNew;
