import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import InstallerField from './InstallerField/InstallerField';
import { Introduction } from '../../../types/Introduction';
import { SuncleButton, PendingBar, InfoDetail, CancelButton, TextInput } from '../../../components';
import useAxios from '../../../lib/hooks/useAxios';
import formatIntro from '../../../lib/utils/formatIntro';
import IntroductionContext from '../../../lib/context/IntroductionContext';
import { Installer } from '../../../types/Installer';
import { Lead } from '../../../types/Lead';

const useStyles = makeStyles(() => ({
  formInput: {
    display: 'block',
    maxWidth: 565,
    marginTop: 8,
  },
  select: {
    maxWidth: 180,
  },
}));

const prefectures = ['北海道', '青森県', '岩手県', '宮城県', '秋田県',
  '山形県', '福島県', '新潟県', '茨城県', '栃木県', '群馬県', '埼玉県',
  '千葉県', '東京都', '神奈川県', '山梨県', '静岡県', '長野県', '岐阜県',
  '愛知県', '三重県', '富山県', '石川県', '福井県', '滋賀県', '京都府',
  '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県',
  '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県',
  '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];

const copyIntroduction = (intro: Introduction) => {
  if (intro) {
    return {
      ...intro,
      system_attr: {
        ...intro.system_attr,
      },
    };
  }
  return intro;
};

type FormInputNames = 'family_name' | 'given_name' | 'family_name_kana' | 'given_name_kana' | 'email' | 'phone' | 'postcode' | 'addr_l1' | 'addr_l2' | 'system_attr.lat' | 'system_attr.lng' | 'age_home' | 'notes_to_installer';

type IntroductionEditPageProps = {
  intro: Introduction;
  introId: string;
};

const IntroductionEditPage: React.FC<IntroductionEditPageProps> = ({ intro, introId }) => {
  const { state: { introductions }, dispatch: introDispatch } = useContext(IntroductionContext);
  const {
    updateIntroduction,
    getServiceAreaByPrefecture,
    getServiceAreaInstallers,
    getIntroductionLeads,
    deleteLead,
    createLead,
  } = useAxios();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [fetchingInstallers, setFetchingInstallers] = useState(false);
  const [installers, setInstallers] = useState<Installer[]>();
  const [leads, setLeads] = useState<Lead[]>();
  const [installerChangeDisabled, setInstallerChangeDisabled] = useState(false);
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const [values, setValues] = useState<Introduction>();
  const [installerIds, setInstallerIds] = useState<number[]>();
  const classes = useStyles();

  useEffect(() => {
    if (intro) {
      if (intro.status === 'REJECTED' || intro.status === 'APPROVED') {
        setInstallerChangeDisabled(true);
      }
      setValues(copyIntroduction(intro));
      setInstallerIds(intro.installer_ids);
    }
  }, [intro]);

  useEffect(() => {
    const fetchLeads = async () => {
      if (introId) {
        const { data: fetchedLeads } = await getIntroductionLeads(Number(introId));
        setLeads(fetchedLeads);
      }
    };
    fetchLeads();
  }, [introId]);

  useEffect(() => {
    const fetchInstallers = async () => {
      if (values && values.service_area) {
        setFetchingInstallers(true);
        const { data: fetchedInstallers } = await getServiceAreaInstallers(values.service_area);
        setInstallers(fetchedInstallers);
        setFetchingInstallers(false);
      }
    };

    fetchInstallers();
  }, [values]);

  const handleChange = (name: FormInputNames) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (submitMessage) {
      setSubmitMessage(null);
    }
    // Change handler for all form fields
    if (values) {
      if (name.includes('system_attr')) {
        setValues({
          ...values,
          system_attr: {
            ...values.system_attr,
            [name.split('.')[1]]: event.target.value,
          },
        });
      }
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handleSelectChange = (name: string) => (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => {
    if (submitMessage) {
      setSubmitMessage(null);
    }
    if (values) {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handlePrefectureChange = async (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => {
    if (submitMessage) {
      setSubmitMessage(null);
    }
    if (values) {
      const newValues = { ...values, addr_l1: event.target.value };
      setValues(newValues);
      const { data: serviceArea } = await getServiceAreaByPrefecture(event.target.value, values.postcode);
      setValues({ ...newValues, service_area: serviceArea.area_id });
    }
  };

  const handleInstallerChange = (newInstallerIds: number[]) => {
    setInstallerIds(newInstallerIds);
  };

  const submitLeads = async () => {
    if (installers && leads && installerIds) {
      // 1. Get unique installerIds
      const uniqueIds: number[] = [];
      installerIds.forEach((id) => {
        if (!uniqueIds.includes(id)) uniqueIds.push(id);
      });
      // 2. Make sure all the installer Ids are for installers in service area
      const installersInServiceArea = installers.map((installer) => installer.installer_id);
      const filteredIds = uniqueIds.filter((id) => installersInServiceArea.includes(id));
      // 3. Find leads that need to be deleted from db
      const leadsToDelete = leads.filter((lead) => !filteredIds.includes(lead.installer_id));
      // 4. Build lead that need to be created
      const leadInstallers = leads.map((lead) => lead.installer_id);
      const leadsToCreate = filteredIds.filter((id) => !leadInstallers.includes(id))
        .map((id) => ({
          installer_id: id,
          intro_id: intro.intro_id,
        }));
      return Promise.all([
        ...leadsToDelete.map((lead) => deleteLead(lead.lead_id)),
        ...leadsToCreate.map((lead) => createLead(lead)),
      ]);
    }
    return null;
  };

  const submitIntro = async () => {
    if (values) {
      setFormSubmitting(true);
      if (submitMessage) {
        setSubmitMessage(null);
      }
      try {
        await submitLeads();
        const response = await updateIntroduction(values);
        setFormSubmitting(false);
        if (response.status === 200) {
          const updatedIntro = formatIntro(response.data);
          const updatedIntros = introductions.map((oldIntro: Introduction) => {
            if (oldIntro.intro_id === updatedIntro.intro_id) {
              return updatedIntro;
            }
            return oldIntro;
          });
          introDispatch({
            type: 'set',
            payload: { introductions: updatedIntros },
          });
          setSubmitMessage('Successfully updated the introduction!');
        } else {
          setSubmitMessage('Something went wrong, please try to submit again.');
        }
      } catch (error) {
        setFormSubmitting(false);
        setSubmitMessage('Something went wrong, please try to submit again.');
      }
    }
  };

  const disableSubmit = formSubmitting || !installers || !leads || !installerIds;

  return (
    <>
      <InfoDetail label="Intro ID:" data={values ? values.intro_id : ''} />
      <TextInput
        labelText="Family Name Kanji"
        name="family_name"
        value={values ? values.family_name : ''}
        onChange={handleChange('family_name')}
        fullWidth={true}
      />

      <TextInput
        labelText="Given Name Kanji"
        name="given_name"
        value={values ? values.given_name : ''}
        onChange={handleChange('given_name')}
        fullWidth={true}
      />

      <TextInput
        labelText="Family Name Kana"
        name="family_name_kana"
        value={values ? values.family_name_kana : ''}
        onChange={handleChange('family_name_kana')}
        fullWidth={true}
      />

      <TextInput
        labelText="Given Name Kana"
        name="given_name_kana"
        value={values ? values.given_name_kana : ''}
        onChange={handleChange('given_name_kana')}
        fullWidth={true}
      />

      <TextInput
        labelText="Email"
        name="email"
        value={values ? values.email : ''}
        onChange={handleChange('email')}
        fullWidth={true}
      />

      <TextInput
        labelText="Phone"
        name="phone"
        value={values ? values.phone : ''}
        onChange={handleChange('phone')}
        fullWidth={true}
      />

      <TextInput
        labelText="Postcode"
        name="postcode"
        value={values ? values.postcode : ''}
        onChange={handleChange('postcode')}
        fullWidth={true}
      />

      <FormControl className={classes.select}>
        <InputLabel htmlFor="prefecture-select">Prefecture</InputLabel>
        <Select
          onChange={handlePrefectureChange}
          id="prefecture-select"
          name="addr_l1"
          value={values ? values.addr_l1 : '北海道'}
        >
          {prefectures.map((prefecture) => <MenuItem value={prefecture} key={prefecture}>{prefecture}</MenuItem>)}
        </Select>
      </FormControl>

      <TextInput
        labelText="Address Line 2"
        name="addr_l2"
        value={values ? values.addr_l2 : ''}
        onChange={handleChange('addr_l2')}
        fullWidth={true}
      />

      <TextInput
        labelText="Latitude"
        name="system_attr.lat"
        value={values ? values.system_attr.lat : ''}
        onChange={handleChange('system_attr.lat')}
        fullWidth={true}
      />

      <TextInput
        labelText="Longitude"
        name="system_attr.lng"
        value={values ? values.system_attr.lng : ''}
        onChange={handleChange('system_attr.lng')}
        fullWidth={true}
      />

      <FormControl>
        <InputLabel htmlFor="age_home-select">Home Age</InputLabel>
        <Select
          className={classes.select}
          value={values ? values.age_home : 0}
          onChange={handleSelectChange('age_home')}
          id="age_home-select"
          name="age_home"
        >
          <MenuItem value={0}>Not reported</MenuItem>
          <MenuItem value={1}>10年以内</MenuItem>
          <MenuItem value={2}>11年~20年</MenuItem>
          <MenuItem value={3}>21年~30年</MenuItem>
          <MenuItem value={4}>30年以上</MenuItem>
        </Select>
      </FormControl>

      <TextInput
        labelText="Notes To Installer"
        name="notes_to_installer"
        value={values ? values.notes_to_installer : ''}
        onChange={handleChange('notes_to_installer')}
        fullWidth={true}
        multiline={true}
      />

      <Typography>Installers</Typography>
      {!installers && <><br /><PendingBar /></>}
      {intro
        && installers
        && (
        <InstallerField
          installers={installers}
          installerIds={intro.installer_ids}
          onChange={(data: (number)[]) => handleInstallerChange(data)}
          fetchingInstallers={fetchingInstallers}
          disabled={installerChangeDisabled}
        />
        )}
      <div>
        <SuncleButton onClick={submitIntro} disabled={disableSubmit}>Submit</SuncleButton>
        <CancelButton />
      </div>
      {submitMessage && <p>{submitMessage}</p>}
      {formSubmitting && <PendingBar />}
    </>
  );
};

export default IntroductionEditPage;
