import React, { useState, useEffect, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Introduction } from '../../../../types/Introduction';
import { SuncleButton, PendingBar } from '../../../../components';
import useAxios from '../../../../lib/hooks/useAxios';
import formatIntro from '../../../../lib/utils/formatIntro';
import RoleContext from '../../../../lib/context/RoleContext';
import IntroductionContext from '../../../../lib/context/IntroductionContext';
import IntroductionCountContext from '../../../../lib/context/IntroductionCountContext';
import { Installer } from '../../../../types/Installer';

const useStyles = makeStyles(() => ({
  controlsRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  select: {
    flexShrink: 0,
    marginRight: 18,
    minWidth: 130,
    maxWidth: 130,
  },
  submit: {
    maxWidth: 120,
    marginTop: 16,
  },
}));

type ApprovalFormProps = {
  intro: Introduction,
  inactiveInstallers: Installer[];
};

type IntroductionState = {
  approvalStatus: number;
  rejectionEmail: string | null;
  partnerNotes: string | null;
  silentRejection: boolean | null;
};

type Status = {
  status_id: number;
  status: 'NEW' | 'PROCESSING' | 'APPROVED' | 'REJECTED' | 'QUALIFIED';
};

const ApprovalForm: React.FC<ApprovalFormProps> = ({ intro, inactiveInstallers }) => {
  const { state: { role } } = useContext(RoleContext);
  const { state: { introductions }, dispatch: introDispatch } = useContext(IntroductionContext);
  const { state: { pendingIntroductions }, dispatch: approvalDispatch } = useContext(IntroductionCountContext);
  const { updateIntroductionStatus, getStatusesForTable } = useAxios();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [statuses, setStatuses] = useState<Status[]>();
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const [values, setValues] = useState<IntroductionState>({
    approvalStatus: 5,
    rejectionEmail: null,
    partnerNotes: null,
    silentRejection: null,
  });
  const classes = useStyles();

  useEffect(() => {
    const fetchStatuses = async () => {
      const response = await getStatusesForTable('introduction');
      if (response && response.status === 200) {
        setStatuses(response.data);
      }
    };

    fetchStatuses();
  }, []);

  useEffect(() => {
    if (intro) {
      // Disable the form if the introduction already is approved or rejected
      setFormDisabled(intro.status === 'APPROVED' || intro.status === 'REJECTED');
      setValues({
        approvalStatus: intro.status_id,
        rejectionEmail:
            intro && intro.staff_eval_memo
              ? intro.staff_eval_memo
              : '',
        partnerNotes:
            intro && intro.staff_eval_memo
              ? intro.staff_eval_memo
              : '',
        silentRejection: false,
      });
    }
  }, [intro]);

  const handleCheckboxChange = (name: 'silentRejection') => (event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: any; checked?: boolean | string; }>) => {
    const inputValue = event.target.checked === true;
    setValues({ ...values, [name]: inputValue });
  };

  const handleChange = (name: 'approvalStatus' | 'rejectionEmail' | 'partnerNotes') => (
    event: React.ChangeEvent<HTMLInputElement | {
      name?: string | undefined;
      value: unknown;
    }
    >,
  ) => {
    if (submitMessage) {
      setSubmitMessage(null);
    }
    setValues({ ...values, [name]: event.target.value });

    // Enable/disable form submit
    // Use the value from the event, instead of the state, for the changed input
    const rejectionText = name === 'rejectionEmail' ? event.target.value : values.rejectionEmail;
    const selectedStatus = name === 'approvalStatus' ? event.target.value : values.approvalStatus;
    const statusText = statuses && statuses.filter((status) => status.status_id === selectedStatus)[0].status;
    if (
      statusText === 'APPROVED'
      || statusText === 'PROCESSING'
      || statusText === 'QUALIFIED'
      || (statusText === 'REJECTED' && rejectionText !== '')
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const submitIntroduction = async () => {
    setFormDisabled(true);
    setFormSubmitting(true);
    if (submitMessage) {
      setSubmitMessage(null);
    }
    if (statuses) {
      try {
        const selectedStatus = statuses.filter((status) => values.approvalStatus === status.status_id)[0].status;
        const additionalText = selectedStatus === 'REJECTED'
          ? values.rejectionEmail
          : values.partnerNotes;
        const response = await updateIntroductionStatus(intro.intro_id, {
          status: values.approvalStatus,
          additionalText,
          excludeHomeOwner: Boolean(values.silentRejection),
        });
        setFormSubmitting(false);
        if (response && response.status === 200) {
          const updatedIntro = formatIntro(response.data);
          const updatedIntros = introductions.map((oldIntro: Introduction) => {
            if (oldIntro.intro_id === updatedIntro.intro_id) {
              return updatedIntro;
            }
            return oldIntro;
          });
          introDispatch({
            type: 'set',
            payload: { introductions: updatedIntros },
          });
          if (pendingIntroductions) {
            approvalDispatch({
              type: 'set',
              payload: { pendingIntroductions: pendingIntroductions - 1 },
            });
          }
          setFormDisabled(selectedStatus === 'APPROVED' || selectedStatus === 'REJECTED');
          setSubmitMessage('Successfully updated the introduction!');
        }
      } catch (error) {
        setFormDisabled(false);
        setFormSubmitting(false);
        setSubmitMessage('Something went wrong, please try to submit again.');
      }
    }
  };

  return (
    <>
      <div className={classes.controlsRow}>
        <FormControl className={classes.select}>
          <InputLabel htmlFor="status-select">Status (必須)</InputLabel>
          <Select
            id="status-select"
            value={values.approvalStatus || ''}
            name="approvalStatus"
            onChange={handleChange('approvalStatus')}
            disabled={formDisabled || role === 'readonly' || !statuses}
          >
            {statuses && statuses.map((status) => (
              <MenuItem key={status.status_id} value={status.status_id}>{status.status}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {statuses && statuses.filter((status) => status.status_id === values.approvalStatus)[0].status === 'REJECTED' && (
          <FormControl fullWidth>
            <InputLabel htmlFor="reject-email">Rejection email (必須)</InputLabel>
            <Input
              id="reject-email"
              name="rejectionEmail"
              value={values.rejectionEmail}
              onChange={handleChange('rejectionEmail')}
              multiline
              disabled={formDisabled || role === 'readonly'}
            />
          </FormControl>
        )}
        {statuses && statuses.filter((status) => status.status_id === values.approvalStatus)[0].status === 'APPROVED' && (
          <FormControl fullWidth>
            <InputLabel htmlFor="partner-notes">Partner email notes</InputLabel>
            <Input
              id="partner-notes"
              value={values.partnerNotes}
              name="partnerNotes"
              onChange={handleChange('partnerNotes')}
              multiline
              disabled={formDisabled || role === 'readonly'}
            />
          </FormControl>
        )}
      </div>
      {statuses && statuses.filter((status) => status.status_id === values.approvalStatus)[0].status === 'REJECTED' && (
        <FormControl>
          <FormControlLabel
            control={(
              <Checkbox
                checked={Boolean(values.silentRejection)}
                onChange={handleCheckboxChange('silentRejection')}
                disabled={formDisabled || role === 'readonly'}
              />
            )}
            label="Silently reject introduction?"
          />
        </FormControl>
      )}
      <SuncleButton
        className={classes.submit}
        disabled={submitDisabled || formDisabled || role === 'readonly' || (inactiveInstallers && inactiveInstallers.length > 0)}
        onClick={submitIntroduction}
      >
        Submit
      </SuncleButton>
      {inactiveInstallers && inactiveInstallers.length > 0 && (
        <>
          <br /><Typography>Some of the installers in this introduction are inactive. Please change installers before submitting.</Typography>
          <p>
            {inactiveInstallers.map((installer) => (
              <Fragment key={installer.installer_id}>{installer.companyName}<br /></Fragment>
            ))}
          </p>
        </>
      )}
      {submitMessage && <p>{submitMessage}</p>}
      {formSubmitting && <PendingBar />}
    </>
  );
};

export default ApprovalForm;
