import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import useAxios from '../../../../lib/hooks/useAxios';
import BillingCycle from './BillingCycle';
import { Error, PendingBar } from '../../../../components';

const Invoice: React.FC = () => {
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const unmounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billingCycles, setBillingCycles] = useState<{bc_id: number, run_date: string}[]>([]);
  const [installerInvoiceFileNames, setInstallerInvoiceFileNames] = useState<{ [key: string]: string[] }>();
  const [installerHasNoInvoices, setInstallerHasNoInvoices] = useState(false);
  const { getInstallerBillingCycles, getInstallerInvoiceFileNames } = useAxios();
  const { installerId } = useParams();

  useEffect(() => {
    const fetchBillingCycles = async () => {
      if (installerId) {
        setIsLoading(true);
        const response = await getInstallerBillingCycles(installerId);
        if (!unmounted.current) {
          if (response.status === 200) {
            if (response.data.length === 0) {
              setInstallerHasNoInvoices(true);
            } else {
              setBillingCycles(response.data);
              const { data: fileNames } = await getInstallerInvoiceFileNames(installerId);
              setInstallerInvoiceFileNames(fileNames);
            }
          } else {
            setShouldDisplayError(true);
          }
          setIsLoading(false);
        }
      }
    };
    fetchBillingCycles();
    // Prevent react from trying to update state if component get unmounted
    return () => { unmounted.current = true; };
  }, [installerId]);

  return (
    <>
      {shouldDisplayError && <Error shouldDisplay={shouldDisplayError} />}
      {!shouldDisplayError && (
        <>
          <List>
            {installerHasNoInvoices && (
              <Typography>Installer has no invoices</Typography>
            )}
            {isLoading && <PendingBar />}
            {installerInvoiceFileNames && billingCycles.map((bc) => (
              <BillingCycle key={bc.bc_id} billingCycleId={bc.bc_id} runDate={bc.run_date} fileNames={installerInvoiceFileNames[bc.bc_id]} />
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default Invoice;
