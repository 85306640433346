import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';
import IntroductionTableContext, { withIntroductionsTableContextProvider } from './IntroductionsTableContext';
import IntroductionCountContext from '../../../lib/context/IntroductionCountContext';
import IntroductionContext from '../../../lib/context/IntroductionContext';
import { Introduction } from '../../../types/Introduction';
import useAxios from '../../../lib/hooks/useAxios';
import PageTemplate from '../../PageTemplate';
import { Sheet, PendingBar, PaginatedTable, TableSearchFilter } from '../../../components';
import formatIntro from '../../../lib/utils/formatIntro';

const useStyles = makeStyles(() => ({
  noIntros: {
    marginTop: 16,
  },
  introDetailsButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: '#5d2f91',
    cursor: 'pointer',
    '&:hover': {
      color: '#4D2777',
    },
  },
}));

const formatIntros = (intros: Introduction[]) => intros.map(formatIntro);

type IntrosState = {
  filter: string;
  billingCycle: string;
};

type IntroductionsProps = {};

const Introductions: React.FC<IntroductionsProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    getRecentIntroductions,
    getIntrosForBillingCycle,
    getIntroductionCount,
  } = useAxios();
  const { dispatch: approvalCountDispatch } = useContext(IntroductionCountContext);
  const { dispatch: introTableDispatch } = useContext(IntroductionTableContext);
  const { state: { introductions }, dispatch } = useContext(IntroductionContext);
  const [useFilteredIntros, setUseFilteredIntros] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noIntros, setNoIntros] = useState(false);
  const [filteredIntros, setFilteredIntros] = useState<Introduction[]>([]);
  const [totalIntros, setTotalIntros] = useState(0);
  const [values, setValues] = useState<IntrosState>({
    filter: '',
    billingCycle: 'current',
  });

  useEffect(() => {
    // Change the count used for pagination, when filtering intros
    if (useFilteredIntros) {
      setTotalIntros(filteredIntros.length);
    } else {
      setTotalIntros(introductions.length);
    }
  }, [filteredIntros, introductions, useFilteredIntros]);

  useEffect(() => {
    const fetchIntroductions = async () => {
      setIsLoading(true);
      const response = await getRecentIntroductions();
      const intros = formatIntros(response.data);
      dispatch({ type: 'set', payload: { introductions: intros } });
      setIsLoading(false);
      !intros.length ? setNoIntros(true) : setNoIntros(false);
      if (intros.length > 0 && values.filter !== '') {
        setUseFilteredIntros(true);
        setFilteredIntros(
          intros.filter((intro: Introduction) => JSON.stringify(Object.values(intro)).includes(values.filter)),
        );
      }
    };

    const updateIntroductionCount = async () => {
      const approvalCount = await getIntroductionCount();
      approvalCountDispatch({ type: 'set', payload: { pendingIntroductions: approvalCount } });
    };

    fetchIntroductions();
    updateIntroductionCount();
  }, []);

  const handleBillingCycleChange = async (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => {
    if (event.target.value !== values.billingCycle) {
      setValues({ ...values, billingCycle: event.target.value });
      // If changing billing cycles:
      // 1. Clear out the introduction data and reset to first page of table
      setTotalIntros(0);
      dispatch({ type: 'set', payload: { introductions: [] } });
      setFilteredIntros([]);
      introTableDispatch({ type: 'goTo', payload: { nextPage: 0 } });
      history.push('/introductions');
      // 2. Set loading status and clear no intro message
      setIsLoading(true);
      setNoIntros(false);
      // 3. Get and set intros for the billing cycle
      const response = await getIntrosForBillingCycle(event.target.value);
      const intros = formatIntros(response.data);
      dispatch({
        type: 'set',
        payload: { introductions: intros },
      });
      setIsLoading(false);
      setTotalIntros(intros.length);
      if (!intros.length) setNoIntros(true);
    }
  };

  const filterIntroductions = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      setUseFilteredIntros(true);
      // Reset to first page of table
      introTableDispatch({ type: 'goTo', payload: { nextPage: 0 } });
      history.push('/introductions');
      setFilteredIntros(
        introductions.filter((intro: Introduction) => JSON.stringify(Object.values(intro)).includes(event.target.value)),
      );
    } else {
      setUseFilteredIntros(false);
      setFilteredIntros([]);
    }
    setValues({ ...values, filter: event.target.value });
  };

  return (
    <PageTemplate pageTitle="Introductions">
      <Sheet>
        <TableSearchFilter
          values={values}
          onBillingCycleChange={handleBillingCycleChange}
          onSearchFilterChange={filterIntroductions}
        />
        <PaginatedTable
          columns={[
            {
              Header: 'Link',
              Cell: (opts: any) => (<Link to={`/introductions/${opts.cell.value}`}><LinkIcon /></Link>),
              accessor: 'intro_id',
              id: new Date().getTime(), // id is used for React key prop when creating the table
              disableSortBy: true,
            },
            {
              Header: 'Intro ID',
              accessor: 'intro_id',
            },
            {
              Header: 'Status',
              accessor: 'status',
            },
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Created Date',
              accessor: 'created_at',
            },
          ]}
          data={useFilteredIntros ? filteredIntros : introductions}
          totalRows={totalIntros}
          url="introductions"
          context={IntroductionTableContext}
        />
        {isLoading && <PendingBar />}
        {noIntros && (
          <Typography className={classes.noIntros}>
            There are currently no intros for this billing cycle
          </Typography>
        )}
      </Sheet>
    </PageTemplate>
  );
};

export default withIntroductionsTableContextProvider(Introductions);
