import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useAxios from '../../../../lib/hooks/useAxios';
import formatDate from '../../../../lib/utils/formatDate';
import { Error, PendingBar } from '../../../../components';
import InvoiceLink from './InvoiceLink';
import LeadTable from '../LeadTable';

const useStyles = makeStyles((theme) => ({
  tableText: {
    whiteSpace: 'nowrap',
  },
  downloadButton: {
    margin: '14px 0',
    backgroundColor: '#5d2f91',
    color: '#fff',
    '&:hover': {
      color: '#5d2f91',
    },
  },
  tableTitle: {
    border: 'none',
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    outline: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      color: '#5d2f91',
    },
  },
  tableTitleIcon: {
    color: '#5d2f91',
  },
  tableTitleText: {
    marginTop: 7,
  },
  tableSubTitle: {
    paddingLeft: 7,
  },
  collapseBody: {
    overflowX: 'auto',
  },
}));

type BillingCycleProps = {
  isDefaultOpen?: boolean;
  billingCycleId: number;
  runDate: string;
  fileNames: string[];
};

const BillingCycle: React.FC<BillingCycleProps> = ({ isDefaultOpen, billingCycleId, runDate, fileNames }) => {
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [refundData, setRefundData] = useState([]);
  const [filteredFileNames, setFilteredFileNames] = useState<string[]>([]);
  const { getInstallerInvoiceData } = useAxios();
  const { installerId } = useParams();

  useEffect(() => {
    if (isDefaultOpen) {
      setIsOpen(true);
    }
  }, []);

  const getInvoice = async (fileNameMatch: string) => {
    if (installerId) {
      const fileName = fileNames.find((name) => name.includes(fileNameMatch));
      if (fileName) {
        const { data: leads } = await getInstallerInvoiceData(installerId, billingCycleId, fileName);
        return leads.length > 0 ? leads : null;
      }
    }
    return null;
  };

  const getInvoices = async () => {
    if (installerId && fileNames && leadData.length === 0 && refundData.length === 0) {
      try {
        let filesWithData = [...fileNames];
        setIsDownloading(true);
        const [leads, refunds] = await Promise.all([getInvoice('紹介済案件一覧'), getInvoice('案件不成立リクエスト一覧')]);
        if (leads) {
          setLeadData(leads);
        } else {
          filesWithData = filesWithData.filter((name) => !name.includes('紹介済案件一覧'));
        }
        if (refunds) {
          setRefundData(refunds);
        } else {
          filesWithData = filesWithData.filter((name) => !name.includes('案件不成立リクエスト一覧'));
        }
        setFilteredFileNames(filesWithData);
        setIsDownloading(false);
      } catch (error) {
        setShouldDisplayError(true);
        setIsDownloading(false);
      }
    }
  };

  const classes = useStyles();
  return (
    <>
      <Button
        className={clsx(classes.tableTitle)}
        onClick={() => {
          setIsOpen(!isOpen);
          getInvoices();
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          gutterBottom
          className={classes.tableTitleText}
        >
          {formatDate(runDate)} までの明細
        </Typography>
        {!isOpen && (
          <KeyboardArrowDownIcon
            fontSize="large"
            className={classes.tableTitleIcon}
          />
        )}
        {isOpen && (
          <KeyboardArrowUpIcon
            fontSize="large"
            className={classes.tableTitleIcon}
          />
        )}
      </Button>
      <Collapse in={isOpen} className={clsx(isOpen && classes.collapseBody)}>
        <InvoiceLink
          billingCycleId={billingCycleId}
          onError={setShouldDisplayError}
          runDate={runDate}
          fileNames={filteredFileNames}
        />
        {isDownloading && <PendingBar />}
        {shouldDisplayError && <Error shouldDisplay={shouldDisplayError} />}
        {leadData.length > 0 && (
          <>
            <br />
            <Typography>Leads</Typography>
            <LeadTable data={leadData} />
          </>
        )}
        {refundData.length > 0 && (
          <>
            <br />
            <Typography>Refunds</Typography>
            <LeadTable data={refundData} />
          </>
        )}
      </Collapse>
      <Divider />
    </>
  );
};

export default BillingCycle;
