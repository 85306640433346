import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  flexRow: {
    flex: '1 1',
  },
}));

type FlexItemProps = {
  children?: any;
};

const FlexItem: React.FC<FlexItemProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.flexRow}>{children}</div>;
};

export default FlexItem;
