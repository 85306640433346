import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from './history';
import App from './App';
import { Auth0Provider } from './react-auth0-wrapper';
import './index.scss';

// A function that routes the user to the right place after login
const onRedirectCallback = (appState: any) => {
  history.replace(
    appState && appState.targetUrl
      ? appState.targetUrl
      : `${window.location.pathname}${window.location.search}`,
    { title: document.title },
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_DOMAIN || ''}
    client_id={process.env.REACT_APP_CLIENT_ID || ''}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={process.env.REACT_APP_AUIDENCE || ''}
  >
    <Router history={history}>
      <App />
    </Router>
  </Auth0Provider>,
  document.getElementById('root'),
);
