import React, { createContext, useReducer } from 'react';
import { Introduction as IntroductionType } from '../../types/Introduction';

type IntroductionContextState = {
  introductions: IntroductionType[];
};

type IntroductionContextAction = {
  type: 'set',
  payload: {
    introductions: IntroductionType[];
  }
};

type IntroductionContextArgs = {
  state: IntroductionContextState;
  dispatch: React.Dispatch<IntroductionContextAction>;
};

export const initialState: IntroductionContextState = { introductions: [] };

export function reducer(state: IntroductionContextState, action: IntroductionContextAction) {
  switch (action.type) {
    case 'set':
      return { introductions: action.payload.introductions };
    default:
      return state;
  }
}

const IntroductionContext = createContext({} as IntroductionContextArgs);

export const IntroductionContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  IntroductionContext.displayName = 'IntroductionContext';
  return (
    <IntroductionContext.Provider value={value}>{children}</IntroductionContext.Provider>
  );
};

export default IntroductionContext;
