import { useEffect, useState, useContext } from 'react';
import InstallersContext from '../context/InstallersContext';
import { Introduction } from '../../types/Introduction';
import { Installer } from '../../types/Installer';

const useInstallers = (intro: Introduction): {isLoading: boolean, installersForIntro: Installer[]} => {
  const [installersForIntro, setInstallersForIntro] = useState();
  const { state: { installers } } = useContext(InstallersContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (intro && intro.installer_ids && installers.length !== 0) {
      const foundInstallers = intro.installer_ids.map((installerId) => {
        return installers.find((installer) => {
          return Number(installer.installer_id) === Number(installerId);
        });
      });
      // find with return undefined if it can't find an installer
      // that shouldn't be the case, but we should filter just in case
      // Also, typescript doesn't understand that we're filtering out the undefined
      // so installersForIntro is not typed
      setInstallersForIntro(foundInstallers.filter((installer) => installer !== undefined));
      setIsLoading(false);
    }
  }, [installers, intro]);

  return {
    isLoading,
    installersForIntro,
  };
};

export default useInstallers;
