import React from 'react';
import ServiceAreaList from './ServiceAreaList';
import { InfoDetail } from '../../../components';
import { Installer } from '../../../types/Installer';

type InstallerDetailsProps = {
  installer: Installer;
};

const InstallerDetails: React.FC<InstallerDetailsProps> = ({ installer }) => {
  return (
    <>
      <InfoDetail
        label="Installer Id:"
        data={installer ? installer.installer_id : ''}
      />
      <InfoDetail
        label="Company Name:"
        data={installer ? installer.companyName : ''}
      />
      <InfoDetail
        label="Active Status:"
        data={installer && installer.active ? 'Active' : 'Inactive'}
      />
      <InfoDetail
        label="Address:"
        data={installer ? installer.address : ''}
      />
      <InfoDetail
        label="Phone Number:"
        data={installer ? installer.phone : ''}
      />
      <InfoDetail
        label="Contact Name:"
        data={`${installer ? installer.familyName : ''} ${installer ? installer.givenName : ''} - ${installer ? installer.familyNameFuri : ''} ${installer ? installer.givenNameFuri : ''}`}
      />
      <InfoDetail
        label="Email for Admin:"
        data={installer ? installer.emailForAdmin : ''}
      />
      <InfoDetail
        label="Email for Leads:"
        data={installer ? installer.emailForLeads.join(', ') : ''}
      />
      <InfoDetail label="Service Areas:">
        {installer && installer.serviceArea ? <ServiceAreaList serviceAreas={installer.serviceArea} /> : ''}
      </InfoDetail>
      <InfoDetail
        label="Marketing Text:"
        data={installer ? installer.marketingText : ''}
      />
      <InfoDetail
        label="Logo:"
        imageName={installer ? installer.logo : ''}
      />
    </>
  );
};

export default InstallerDetails;
