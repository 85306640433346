import React, { useState, useEffect, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import PageTemplate from '../../PageTemplate';
import { Sheet, PendingBar, CreateButton } from '../../../components';
import { Staff } from '../../../types/Staff';
import StaffContext from '../../../lib/context/StaffContext';

import useAxios from '../../../lib/hooks/useAxios';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#e59924',
    color: '#fff',
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: '#e5ab24',
    },
    '&:disabled': {
      color: '#5d2f91',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  buttonSecondary: {
    backgroundColor: '#5d2f91',
    color: '#fff',
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: '#4d2777',
    },
    '&:disabled': {
      color: '#5d2f91',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  makeRed: {
    color: 'red',
  },
}));

const defaultStaff = { name: '', email: '', user_id: '', picture: '', role: '' };

const StaffIndex: React.FC = () => {
  const { state: { staff }, dispatch } = useContext(StaffContext);
  const classes = useStyles();
  const { getAllStaff, deleteStaff, resetStaffPassword } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const [open, setOpen] = useState(false);
  const [staffToBeDeleted, setStaffToBeDeleted] = useState<Staff>(defaultStaff);

  useEffect(() => {
    const fetchStaff = async () => {
      setIsLoadingSkeleton(true);
      setIsLoading(true);
      const response = await getAllStaff();
      if (response.status === 200) {
        dispatch({ type: 'set', payload: { staff: response.data } });
      }
      setIsLoadingSkeleton(false);
      setIsLoading(false);
    };
    if (staff.length === 0) {
      fetchStaff();
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendDeleteStaffRequest = async (staffToDelete: Staff) => {
    handleClose();
    setIsLoading(true);
    deleteStaff(staffToDelete).then((response) => {
      if (response.status === 200) {
        const filtered = staff.filter((u) => u !== staffToDelete);
        dispatch({ type: 'set', payload: { staff: filtered } });
      } else {
        // TODO: handle case when could not delete staff
      }
      setIsLoading(false);
    });
  };

  const handleDeleteRequest = (u: Staff) => {
    setStaffToBeDeleted(u);
    handleClickOpen();
  };

  return (
    <PageTemplate pageTitle="Manage Staff">
      <Sheet>
        <div className={classes.title}>
          <Typography>Staff List</Typography>
          <CreateButton to="staff/new" />
        </div>
        <div>
          <List dense={true}>
            {staff.map((u: Staff) => (
              <ListItem key={u.email} divider={true}>
                <ListItemText
                  primary={u.name}
                  secondary={` Role: ${u.role} Email: ${u.email}`}
                />
                <ListItemSecondaryAction>
                  <Button onClick={() => resetStaffPassword({ email: u.email })} className={classes.makeRed}>Reset password</Button>
                  {/* TODO: Enable editting (change role, name, email) */}
                  {/* <IconButton edge="end" aria-label="delete">
                    <EditIcon />
                  </IconButton> */}
                  <IconButton onClick={() => handleDeleteRequest(u)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
        {isLoadingSkeleton && <Skeleton variant="rect" width="100%" height={64 * 4} />}
        {isLoading && <PendingBar />}
      </Sheet>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-delete-staff"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {staffToBeDeleted.name}?<br />
            {staffToBeDeleted.email}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => sendDeleteStaffRequest(staffToBeDeleted)} className={classes.makeRed}>
            Delete
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </PageTemplate>
  );
};

export default StaffIndex;
