import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { parse } from 'json2csv';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GetAppIcon from '@material-ui/icons/GetApp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useAxios from '../../../../lib/hooks/useAxios';
import formatDate from '../../../../lib/utils/formatDate';
import downloadInvoice from '../../../../lib/utils/downloadInvoice';
import { Error, PendingBar, SuncleButton } from '../../../../components';

const useStyles = makeStyles((theme) => ({
  tableText: {
    whiteSpace: 'nowrap',
  },
  downloadButton: {
    margin: '14px 0',
    backgroundColor: '#5d2f91',
    color: '#fff',
    '&:hover': {
      color: '#5d2f91',
    },
  },
  tableTitle: {
    border: 'none',
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    outline: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      color: '#5d2f91',
    },
  },
  tableTitleIcon: {
    color: '#5d2f91',
  },
  tableTitleText: {
    marginTop: 7,
  },
  collapseBody: {
    overflowX: 'auto',
  },
  invoiceDownload: {
    minWidth: 48,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  invoiceDownloadIcon: {
    color: '#5d2f91',
  },
}));

type Summary = {
  company_name: string;
  installer_id: number;
  leads: string;
  refunds: string;
};

type BillingCycleProps = {
  isDefaultOpen?: boolean;
  billingCycleId: number;
  runDate: Date;
};

const BillingCycle: React.FC<BillingCycleProps> = ({ isDefaultOpen, billingCycleId, runDate }) => {
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { getBillingCycleSummaries, getInstallerInvoice } = useAxios();
  const [summaryData, setSummaryData] = useState<Summary[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (isDefaultOpen) {
      setIsOpen(true);
    }
  }, []);

  const getSummaryData = async () => {
    if (summaryData.length === 0) {
      setIsDownloading(true);
      const { data, status } = await getBillingCycleSummaries(billingCycleId);
      if (status === 200) {
        setSummaryData(data);
      } else {
        setShouldDisplayError(true);
      }
      setIsDownloading(false);
    }
  };

  const downloadCsv = async () => {
    const csv = await parse(summaryData, { withBOM: true, excelStrings: true, fields: Object.keys(summaryData[0]) });
    const fileName = `${formatDate(runDate)}_billing-cycle-summary.csv`;
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(
        new Blob([csv]),
        decodeURIComponent(fileName),
      ); // for ie10
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(new Blob([csv]));
      a.href = url;
      a.download = decodeURIComponent(fileName);
      a.target = '_blank';
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
  };

  const handleInvoiceDownload = async (companyName: string, installerId: number, fileType: 'lead' | 'refund') => {
    const datePart = formatDate(runDate).replace('-', '').slice(0, 6);
    let fileName = `${datePart}_`;
    fileName += fileType === 'lead' ? '紹介済案件一覧' : '案件不成立リクエスト一覧';
    fileName += datePart === '201909' || datePart === '201910' ? '.csv' : '.xlsx';
    const { data } = await getInstallerInvoice(installerId, billingCycleId, fileName);
    downloadInvoice(`${companyName}-${fileName}`, data);
  };

  return (
    <>
      <Button
        className={clsx(classes.tableTitle)}
        onClick={() => {
          setIsOpen(!isOpen);
          getSummaryData();
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          gutterBottom
          className={classes.tableTitleText}
        >
          {formatDate(runDate)} Summary
        </Typography>
        {!isOpen && (
          <KeyboardArrowDownIcon
            fontSize="large"
            className={classes.tableTitleIcon}
          />
        )}
        {isOpen && (
          <KeyboardArrowUpIcon
            fontSize="large"
            className={classes.tableTitleIcon}
          />
        )}
      </Button>
      <Collapse in={isOpen} className={clsx(isOpen && classes.collapseBody)}>
        {isDownloading && <PendingBar />}
        {shouldDisplayError && <Error shouldDisplay={shouldDisplayError} />}
        {summaryData.length > 0 && (
          <>
            <SuncleButton onClick={downloadCsv}>Download CSV</SuncleButton>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Installer Id</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Leads</TableCell>
                  <TableCell>Refunds</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryData.map((row) => (
                  <TableRow key={row.installer_id}>
                    <TableCell>{row.installer_id}</TableCell>
                    <TableCell><Link to={`/installers/${row.installer_id}`}>{row.company_name}</Link></TableCell>
                    <TableCell>
                      <>
                        { row.leads !== '0'
                          && (
                          <Button
                            className={classes.invoiceDownload}
                            onClick={() => handleInvoiceDownload(row.company_name, row.installer_id, 'lead')}
                            endIcon={<GetAppIcon className={classes.invoiceDownloadIcon} />}
                          >
                            {row.leads}
                          </Button>
                          )}
                        { row.leads === '0' && row.leads}
                      </>
                    </TableCell>
                    <TableCell>
                      <>
                        { row.refunds !== '0'
                          && (
                          <Button
                            className={classes.invoiceDownload}
                            onClick={() => handleInvoiceDownload(row.company_name, row.installer_id, 'refund')}
                            endIcon={<GetAppIcon className={classes.invoiceDownloadIcon} />}
                          >
                            {row.refunds}
                          </Button>
                          )}
                        { row.refunds === '0' && row.refunds}
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </Collapse>
      <Divider />
    </>
  );
};

export default BillingCycle;
