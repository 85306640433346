import { useEffect, useContext, useState } from 'react';
import useAxios from './useAxios';
import InstallersContext from '../context/InstallersContext';
import { Installer } from '../../types/Installer';

const useInstaller = (installerId: string | number | undefined) => {
  const { getInstallers } = useAxios();
  const { state: { installers }, dispatch } = useContext(InstallersContext);
  const [isLoading, setIsLoading] = useState(false);
  const [installer, setInstaller] = useState<Installer>();

  useEffect(() => {
    const fetchInstaller = async () => {
      if (installerId) {
        let currentInstaller = installers.find((i) => Number(installerId) === Number(i.installer_id));
        if (!currentInstaller) {
          setIsLoading(true);
          const { data: fetchedInstallers } = await getInstallers();
          dispatch({ type: 'set', payload: { installers: fetchedInstallers } });
          currentInstaller = fetchedInstallers.find((i: Installer) => Number(installerId) === Number(i.installer_id));
          setIsLoading(false);
        }
        setInstaller(currentInstaller);
      }
    };

    fetchInstaller();
  }, [installerId]);

  return {
    isLoading,
    installer,
  };
};

export default useInstaller;
