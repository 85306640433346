import React, { useEffect, useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import LinkIcon from '@material-ui/icons/Link';
import { makeStyles } from '@material-ui/core/styles';
import PageTemplate from '../../PageTemplate';
import { Sheet, PendingBar, CreateButton, PaginatedTable } from '../../../components';
import useAxios from '../../../lib/hooks/useAxios';
import { Installer } from '../../../types/Installer';
import { ServiceAreas } from '../../../types/ServiceAreas';
import InstallersTableContext, { withInstallersTableContextProvider } from './InstallersTableContext';
import InstallersContext from '../../../lib/context/InstallersContext';

const useStyles = makeStyles(() => ({
  controlsRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  select: {
    flexShrink: 0,
    marginRight: 16,
    minWidth: 120,
  },
  noInstallers: {
    marginTop: 16,
  },
  introDetailsButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: '#5d2f91',
    cursor: 'pointer',
    '&:hover': {
      color: '#4D2777',
    },
  },
  serviceAreaCell: {
    minWidth: 245,
  },
  active: {
    color: 'green',
  },
  inactive: {
    color: 'red',
  },
  newButton: {
    marginLeft: 16,
  },
}));

const SERVICE_AREAS: { value: ServiceAreas, label: string }[] = [
  { value: 'hokkaido-west', label: '北海道-西' },
  { value: 'hokkaido-north', label: '北海道-北' },
  { value: 'hokkaido-east', label: '北海道-東' },
  { value: 'aomori', label: '青森県' },
  { value: 'iwate', label: '岩手県' },
  { value: 'miyagi', label: '宮城県' },
  { value: 'akita', label: '秋田県' },
  { value: 'yamagata', label: '山形県' },
  { value: 'fukushima', label: '福島県' },
  { value: 'niigata', label: '新潟県' },
  { value: 'ibaraki', label: '茨城県' },
  { value: 'tochigi', label: '栃木県' },
  { value: 'gunma', label: '群馬県' },
  { value: 'saitama', label: '埼玉県' },
  { value: 'chiba', label: '千葉県' },
  { value: 'tokyo', label: '東京都' },
  { value: 'kanagawa', label: '神奈川県' },
  { value: 'yamanashi', label: '山梨県' },
  { value: 'shizuoka', label: '静岡県' },
  { value: 'nagano', label: '長野県' },
  { value: 'gifu', label: '岐阜県' },
  { value: 'aichi', label: '愛知県' },
  { value: 'mie', label: '三重県' },
  { value: 'toyama', label: '富山県' },
  { value: 'ishikawa', label: '石川県' },
  { value: 'fukui', label: '福井県' },
  { value: 'shiga', label: '滋賀県' },
  { value: 'kyoto', label: '京都府' },
  { value: 'osaka', label: '大阪府' },
  { value: 'hyogo', label: '兵庫県' },
  { value: 'nara', label: '奈良県' },
  { value: 'wakayama', label: '和歌山県' },
  { value: 'tottori', label: '鳥取県' },
  { value: 'shimane', label: '島根県' },
  { value: 'okayama', label: '岡山県' },
  { value: 'hiroshima', label: '広島県' },
  { value: 'yamaguchi', label: '山口県' },
  { value: 'tokushima', label: '徳島県' },
  { value: 'kagawa', label: '香川県' },
  { value: 'ehime', label: '愛媛県' },
  { value: 'kochi', label: '高知県' },
  { value: 'fukuoka', label: '福岡県' },
  { value: 'saga', label: '佐賀県' },
  { value: 'nagasaki', label: '長崎県' },
  { value: 'kumamoto', label: '熊本県' },
  { value: 'oita', label: '大分県' },
  { value: 'miyazaki', label: '宮崎県' },
  { value: 'kagoshima', label: '鹿児島県' },
  { value: 'okinawa', label: '沖縄県' },
];

// const getServiceArea = (areaValue: ServiceAreas) => {
//   const serviceArea = SERVICE_AREAS.find((area) => area.value === areaValue);
//   if (serviceArea) {
//     return serviceArea.label;
//   }
//   return null;
// };

const InstallersIndex: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch: tableDispatch } = useContext(InstallersTableContext);
  const { state: { installers }, dispatch: installersDispatch } = useContext(InstallersContext);
  const [useFilteredInstallers, setUseFilteredInstallers] = useState(false);
  const [filteredInstallers, setFilteredInstallers] = useState<Installer[]>([]);
  const [totalInstallers, setTotalInstallers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    prefecture: '',
  });
  const { getInstallers } = useAxios();

  useEffect(() => {
    const fetchInstallers = async () => {
      setIsLoading(true);
      const { data } = await getInstallers();
      // TODO handle error on installer fetch
      installersDispatch({ type: 'set', payload: { installers: data } });
      setIsLoading(false);
    };
    if (installers.length === 0) {
      fetchInstallers();
    }
  }, []);

  useEffect(() => {
    // Change the count used for pagination, when filtering intros
    if (useFilteredInstallers) {
      setTotalInstallers(filteredInstallers.length);
    } else {
      setTotalInstallers(installers.length);
    }
  }, [filteredInstallers, installers, useFilteredInstallers]);

  const filterInstallers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ prefecture: '' });
    if (event.target.value !== '') {
      setUseFilteredInstallers(true);
      // Reset to first page of table
      tableDispatch({ type: 'goTo', payload: { nextPage: 0 } });
      history.push('/installers');
      setFilteredInstallers(
        installers.filter((installer: Installer) => JSON.stringify(Object.values(installer)).includes(event.target.value)),
      );
    } else {
      setUseFilteredInstallers(false);
      setFilteredInstallers([]);
    }
  };

  const filterInstallersByPrefecture = (event: React.ChangeEvent<{ value: any }>) => {
    setValues({ prefecture: event.target.value });
    if (event.target.value) {
      setUseFilteredInstallers(true);
      // Reset to first page of table
      tableDispatch({ type: 'goTo', payload: { nextPage: 0 } });
      history.push('/installers');
      setFilteredInstallers(
        installers.filter((installer: Installer) => JSON.stringify(installer.areaStat).includes(event.target.value)),
      );
    } else {
      setUseFilteredInstallers(false);
      setFilteredInstallers([]);
    }
  };

  return (
    <PageTemplate pageTitle="Installers">
      <Sheet>
        <div className={classes.controlsRow}>
          <FormControl className={classes.select}>
            <InputLabel htmlFor="prefecture-select">Prefecture</InputLabel>
            <Select
              onChange={filterInstallersByPrefecture}
              id="prefecture-select"
              name="prefecture"
              value={values.prefecture}
            >
              <MenuItem>All</MenuItem>
              {SERVICE_AREAS.map((area) => <MenuItem value={area.value} key={area.value}>{area.label}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="installer-filter">Search</InputLabel>
            <Input id="installer-filter" onChange={filterInstallers} />
          </FormControl>
          <CreateButton to="/installers/new" />
        </div>
        <PaginatedTable
          columns={[
            {
              Header: 'Link',
              Cell: (opts: any) => (
                <Link to={`/installers/${opts.cell.value}`}>
                  <LinkIcon />
                </Link>
              ),
              accessor: 'installer_id',
              id: new Date().getTime(), // id is used for React key prop when creating the table
            },
            {
              Header: 'Company Name',
              accessor: 'companyName',
              id: 'companyName',
            },
            {
              Header: 'Admin Email',
              accessor: 'emailForAdmin',
              id: 'emailForAdmin',
            },
            {
              Header: 'Active Status',
              Cell: (opts: any) => (
                opts.cell.value ? <span className={classes.active}>Active</span> : <span className={classes.inactive}>Inactive</span>
              ),
              accessor: 'active',
              id: 'activeStatus',
            },
          ]}
          data={useFilteredInstallers ? filteredInstallers : installers}
          totalRows={totalInstallers}
          url="installers"
          context={InstallersTableContext}
        />
        {isLoading && <PendingBar />}
      </Sheet>
    </PageTemplate>
  );
};

export default withInstallersTableContextProvider(InstallersIndex);
