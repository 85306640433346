import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';
import RefundsTableContext, { withRefundsTableContextProvider } from './RefundsIndexTableContext';
import { Refund } from '../../../types/Refund';
import useAxios from '../../../lib/hooks/useAxios';
import PageTemplate from '../../PageTemplate';
import { Sheet, PendingBar, PaginatedTable, TableSearchFilter, CreateButton } from '../../../components';
import RefundsContext from '../../../lib/context/RefundsContext';
import formatDate from '../../../lib/utils/formatDate';
import calcRefundDueDate from '../../../lib/utils/calcRefundDueDate';

const useStyles = makeStyles(() => ({
  noRefunds: {
    marginTop: 16,
  },
  introDetailsButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: '#5d2f91',
    cursor: 'pointer',
    '&:hover': {
      color: '#4D2777',
    },
  },
}));

type RefundsState = {
  filter: string;
  billingCycle: string;
};

type RefundsIndexProps = {};

const RefundsIndex: React.FC<RefundsIndexProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { getRefunds } = useAxios();
  const { dispatch: tableDispatch } = useContext(RefundsTableContext);
  const { state: refunds, dispatch: refundsDispatch } = useContext(RefundsContext);
  const [useFilteredRefunds, setUseFilteredRefunds] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noRefunds, setNoRefunds] = useState(false);
  const [refundsForBc, setRefundsForBc] = useState<Refund[]>([]);
  const [filteredRefunds, setFilteredRefunds] = useState<Refund[]>([]);
  const [totalRefunds, setTotalRefunds] = useState(0);
  const [values, setValues] = useState<RefundsState>({
    filter: '',
    billingCycle: 'current',
  });

  useEffect(() => {
    // Change the count used for pagination, when filtering refunds
    if (useFilteredRefunds) {
      setTotalRefunds(filteredRefunds.length);
    } else {
      setTotalRefunds(refundsForBc.length);
    }
  }, [filteredRefunds, refunds.length, refundsForBc.length, useFilteredRefunds]);

  useEffect(() => {
    const fetchRefunds = async () => {
      setIsLoading(true);
      // Set the refund founds in context for initial page load
      if (refunds[values.billingCycle]) {
        setRefundsForBc(refunds[values.billingCycle]);
        if (!refunds[values.billingCycle].length) setNoRefunds(true);
      }
      // fetch and set the refunds from the server, in case the data is stale
      const response = await getRefunds();
      if (response.status === 200) {
        refundsDispatch({ type: 'set', payload: { billingCycle: values.billingCycle, refunds: response.data } });
        setRefundsForBc(response.data);
        !response.data.length ? setNoRefunds(true) : setNoRefunds(false);
      } else {
        // TODO: something went wrong
      }
      setIsLoading(false);
    };

    fetchRefunds();
  }, []);

  const handleBillingCycleChange = async (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => {
    const selectedBillingCycle = event.target.value;
    if (selectedBillingCycle !== values.billingCycle) {
      setValues({ ...values, billingCycle: selectedBillingCycle });
      // If changing billing cycles:
      // 1. Clear out the introduction data and reset to first page of table
      setTotalRefunds(0);
      setRefundsForBc([]);
      setFilteredRefunds([]);
      tableDispatch({ type: 'goTo', payload: { nextPage: 0 } });
      history.push('/refunds');
      setNoRefunds(false);
      // 2. Get the refunds from context, if fetched once already
      if (refunds[selectedBillingCycle]) {
        setRefundsForBc(refunds[selectedBillingCycle]);
        setTotalRefunds(refunds[selectedBillingCycle].length);
        if (!refunds[selectedBillingCycle].length) setNoRefunds(true);
      }
      // 3. Set loading status
      setIsLoading(true);
      // 4. Get and set refunds for the billing cycle, in case of stale data
      const response = await getRefunds(event.target.value);
      refundsDispatch({ type: 'set', payload: { billingCycle: event.target.value, refunds: response.data } });
      setRefundsForBc(response.data);
      setIsLoading(false);
      setTotalRefunds(response.data.length);
      if (!response.data.length) setNoRefunds(true);
    }
  };

  const filterRefunds = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      setUseFilteredRefunds(true);
      // Reset to first page of table
      tableDispatch({ type: 'goTo', payload: { nextPage: 0 } });
      history.push('/refunds');
      setFilteredRefunds(
        refundsForBc.filter((approval: Refund) => JSON.stringify(Object.values(approval)).includes(event.target.value)),
      );
    } else {
      setUseFilteredRefunds(false);
      setFilteredRefunds([]);
    }
    setValues({ ...values, filter: event.target.value });
  };

  return (
    <PageTemplate pageTitle="Refunds">
      <Sheet>
        <TableSearchFilter
          values={values}
          onBillingCycleChange={handleBillingCycleChange}
          onSearchFilterChange={filterRefunds}
        >
          <CreateButton to="/refunds/new" />
        </TableSearchFilter>
        <PaginatedTable
          columns={[
            {
              Header: 'Link',
              Cell: (opts: any) => (
                <Link to={`/refunds/${opts.cell.value}`}>
                  <LinkIcon />
                </Link>
              ),
              accessor: 'refund_id',
              disableSortBy: true,
            },
            {
              Header: 'Lead ID',
              accessor: 'lead_id',
            },
            {
              Header: 'Company Name',
              accessor: 'company_name',
              Cell: ({ row, cell }: any) => (
                <Link to={`/installers/${row.original.installer_id}`}>{cell.value}</Link>
              ),
            },
            {
              Header: 'Reason',
              accessor: 'reason',
            },
            {
              Header: 'Status',
              accessor: 'status_text',
            },
            {
              Header: 'Opened',
              accessor: 'created_at',
              Cell: (opts: any) => (formatDate(opts.cell.value)),
            },
            {
              Header: 'Due',
              Cell: ({ row }: any) => (calcRefundDueDate(row.original.created_at)),
            },
          ]}
          data={useFilteredRefunds ? filteredRefunds : refundsForBc}
          totalRows={totalRefunds}
          url="refunds"
          context={RefundsTableContext}
        />
        {isLoading && <PendingBar />}
        {noRefunds && (
          <Typography className={classes.noRefunds}>
            There are currently no refunds for this billing cycle
          </Typography>
        )}
      </Sheet>
    </PageTemplate>
  );
};

export default withRefundsTableContextProvider(RefundsIndex);
