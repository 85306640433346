import React, { createContext, useReducer } from 'react';

type IntroductionCountContextState = {
  pendingIntroductions: number | null;
};

type IntroductionCountContextAction = {
  type: 'set',
  payload: {
    pendingIntroductions: number;
  }
};

type IntroductionCountContextArgs = {
  state: IntroductionCountContextState;
  dispatch: React.Dispatch<IntroductionCountContextAction>;
};

const initialState: IntroductionCountContextState = { pendingIntroductions: null };

function reducer(state: IntroductionCountContextState, action: IntroductionCountContextAction) {
  switch (action.type) {
    case 'set':
      return { pendingIntroductions: action.payload.pendingIntroductions };
    default:
      return state;
  }
}

const IntroductionCountContext = createContext({} as IntroductionCountContextArgs);

export const IntroductionCountContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  IntroductionCountContext.displayName = 'IntroductionCountContext';
  return (
    <IntroductionCountContext.Provider value={value}>{children}</IntroductionCountContext.Provider>
  );
};

export default IntroductionCountContext;
