// react imports
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui imports
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// Component && custom hook imports
import XLSX from 'xlsx';
import { Typography } from '@material-ui/core';
import PageTemplate from '../../PageTemplate';
import { Sheet, PendingBar, PaginatedTable } from '../../../components';
import useAxios from '../../../lib/hooks/useAxios';
import RoleContext from '../../../lib/context/RoleContext';

// Page Specific Imports
import IncentivesTableContext, { withIncentivesTableContextProvider } from './IncentivesTableContext';

const useStyles = makeStyles(() => ({
  appBar: {
    background: '#fff',
  },
  controlsRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  button: {
    backgroundColor: '#e59924',
    color: '#fff',
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: '#e5ab24',
    },
    '&:disabled': {
      color: '#5d2f91',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  buttonSecondary: {
    backgroundColor: '#5d2f91',
    color: '#fff',
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: '#4d2777',
    },
    '&:disabled': {
      color: '#5d2f91',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  structureCell: {
    flexShrink: 0,
    minWidth: 140,
  },
  nameCell: {
    maxWidth: 350,
  },
  select: {
    flexShrink: 0,
    marginRight: 16,
    minWidth: 120,
  },
  importButton: {
    marginLeft: 16,
  },
  makeRed: {
    color: 'red',
  },
  mgTop: {
    marginTop: 16,
  },
  fileInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileText: {
    color: '#555',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));


const PREFECTURES: { value: string, label: string, count: number }[] = [
  { value: 'hokkaido', label: '北海道', count: 0 },
  { value: 'aomori', label: '青森県', count: 0 },
  { value: 'iwate', label: '岩手県', count: 0 },
  { value: 'miyagi', label: '宮城県', count: 0 },
  { value: 'akita', label: '秋田県', count: 0 },
  { value: 'yamagata', label: '山形県', count: 0 },
  { value: 'fukushima', label: '福島県', count: 0 },
  { value: 'niigata', label: '新潟県', count: 0 },
  { value: 'ibaraki', label: '茨城県', count: 0 },
  { value: 'tochigi', label: '栃木県', count: 0 },
  { value: 'gunma', label: '群馬県', count: 0 },
  { value: 'saitama', label: '埼玉県', count: 0 },
  { value: 'chiba', label: '千葉県', count: 0 },
  { value: 'tokyo', label: '東京都', count: 0 },
  { value: 'kanagawa', label: '神奈川県', count: 0 },
  { value: 'yamanashi', label: '山梨県', count: 0 },
  { value: 'shizuoka', label: '静岡県', count: 0 },
  { value: 'nagano', label: '長野県', count: 0 },
  { value: 'gifu', label: '岐阜県', count: 0 },
  { value: 'aichi', label: '愛知県', count: 0 },
  { value: 'mie', label: '三重県', count: 0 },
  { value: 'toyama', label: '富山県', count: 0 },
  { value: 'ishikawa', label: '石川県', count: 0 },
  { value: 'fukui', label: '福井県', count: 0 },
  { value: 'shiga', label: '滋賀県', count: 0 },
  { value: 'kyoto', label: '京都府', count: 0 },
  { value: 'osaka', label: '大阪府', count: 0 },
  { value: 'hyogo', label: '兵庫県', count: 0 },
  { value: 'nara', label: '奈良県', count: 0 },
  { value: 'wakayama', label: '和歌山県', count: 0 },
  { value: 'tottori', label: '鳥取県', count: 0 },
  { value: 'shimane', label: '島根県', count: 0 },
  { value: 'okayama', label: '岡山県', count: 0 },
  { value: 'hiroshima', label: '広島県', count: 0 },
  { value: 'yamaguchi', label: '山口県', count: 0 },
  { value: 'tokushima', label: '徳島県', count: 0 },
  { value: 'kagawa', label: '香川県', count: 0 },
  { value: 'ehime', label: '愛媛県', count: 0 },
  { value: 'kochi', label: '高知県', count: 0 },
  { value: 'fukuoka', label: '福岡県', count: 0 },
  { value: 'saga', label: '佐賀県', count: 0 },
  { value: 'nagasaki', label: '長崎県', count: 0 },
  { value: 'kumamoto', label: '熊本県', count: 0 },
  { value: 'oita', label: '大分県', count: 0 },
  { value: 'miyazaki', label: '宮崎県', count: 0 },
  { value: 'kagoshima', label: '鹿児島県', count: 0 },
  { value: 'okinawa', label: '沖縄県', count: 0 },
];

// const fileUploadProps = {
//   type: 'file',
//   accept: '.csv,.xlsx',
// };

const emptySelectOption = { value: '', label: '', count: 0 };
const IncentivesIndex: React.FC = () => {
  const classes = useStyles();
  const [incentives, setIncentives] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { state: { role } } = useContext(RoleContext);
  const { dispatch: tableDispatch } = useContext(IncentivesTableContext);
  const [selectedPrefecture, setSelectedPrefecture] = useState({ value: '' });
  const [selectOptions, setSelectOptions] = useState<any[]>([emptySelectOption]);
  const [useFilteredIncentives, setUseFilteredIncentives] = useState(false);
  const [requestJson, setRequestJson] = useState<any>({ incentives: [] });
  // TODO Incentive Type
  const [filteredIncentives, setFilteredIncentives] = useState<any[]>([]);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [fileName, setFileName] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [openErrors, setOpenErrors] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);

  const { getIncentives, uploadIncentives } = useAxios();

  // TODO Incentive Type
  const createSelectOptions = async (data: any) => {
    const countDict: {[key: string]: number} = {};
    data.forEach((incentive: any) => {
      countDict[incentive.prefecture] = countDict[incentive.prefecture] ? countDict[incentive.prefecture] + 1 : 1;
    });
    PREFECTURES.forEach((area: { value: string, label: string, count: number }) => {
      if (countDict[area.label]) {
        // eslint-disable-next-line
        area.count = countDict[area.label];
      }
    });
    setSelectOptions(PREFECTURES.filter((area) => area.count !== 0));
  };

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const { data } = await getIncentives();
      setIncentives(data.map((incentive: any) => {
        return { ...incentive, filterField: JSON.stringify(Object.values(incentive)) };
      }));
      createSelectOptions(data);
      setIsLoading(false);
    };
    if (incentives.length === 0) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Change the count used for pagination, when filtering intros
    if (useFilteredIncentives) {
      setTotalIncentives(filteredIncentives.length);
    } else {
      setTotalIncentives(incentives.length);
    }
  }, [filteredIncentives, incentives, useFilteredIncentives]);

  const filterIncentivesByPrefecture = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedPrefecture({ value: event.target.value });
    if (event.target.value) {
      setUseFilteredIncentives(true);
      const prefectureInfo = selectOptions.filter((so) => so.value === event.target.value)[0];
      tableDispatch({ type: 'goTo', payload: { nextPage: 0 } });
      history.push('/incentives');
      setFilteredIncentives(incentives.filter((incentive: any) => prefectureInfo.label === incentive.prefecture));
    } else {
      setUseFilteredIncentives(false);
      setFilteredIncentives([]);
    }
  };

  const filterIncentives = (event: React.ChangeEvent<{ value: any }>) => {
    setSelectedPrefecture({ value: '' });
    if (event.target.value) {
      setUseFilteredIncentives(true);
      tableDispatch({ type: 'goTo', payload: { nextPage: 0 } });
      history.push('/incentives');
      setFilteredIncentives(
        incentives.filter((incentive: any) => incentive.filterField.includes(event.target.value)),
      );
    } else {
      setUseFilteredIncentives(false);
      setFilteredIncentives([]);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenErrors = () => {
    setOpenErrors(true);
  };

  const handleCloseErrors = () => {
    setOpenErrors(false);
  };

  const validatePercentage = (incentive: any) => {
    let value = incentive.Structure;
    let error = '';
    if (typeof value === 'string') {
      value = Number(value.replace('%', ''));
    }
    if (value === 0) error = 'Column (Structure): Percent should be larger than 0.';
    if (value > 100) error = 'Column (Structure): Percent should be less than 100.';
    const valid = value < 100;
    return {
      valid,
      error,
      // eslint-disable-next-line
      row: incentive.__rowNum__,
    };
  };

  const separateRanges = (range: string) => {
    if (range.indexOf('=') === -1 || range.indexOf('-') === -1) {
      return {
        lower: undefined,
        upper: undefined,
        amount: undefined,
      };
    }
    const bounds = range.split('=')[0];
    const amount = Number(range.split('=')[1]);
    const lower = Number(bounds.split('-')[0]);
    const upper = Number(bounds.split('-')[1]);
    return {
      lower,
      upper,
      amount,
    };
  };

  const validateRangeString = (incentive: any) => {
    if (typeof incentive.Structure !== 'string' || incentive.Structure === '') {
      return {
        valid: false,
        error: 'Column (Structure): Column must not be blank.',
        // eslint-disable-next-line
        row: incentive.__rowNum__,
      };
    }
    let valid = true;
    let error = '';
    const ranges = incentive.Structure.split(';').map((range: string) => separateRanges(range));
    let prev: number | undefined;
    ranges.forEach((range: {lower: number | undefined ; upper: number | undefined ; amount: number | undefined }) => {
      if (Object.values(range).indexOf(undefined) >= 0) {
        valid = false;
        error = 'Column (Structure): Format could not be parsed. Example Format: 0-4=50000;4-10=100000';
      } else {
        if (prev) {
          if (range.lower !== prev) {
            valid = false;
            error = 'Column (Structure): Lower bound of range should match upper bound of previous range. Correct Example: 0-4=50000;4-10=100000 NG Example: 0-4=50000;4.01-10=100000 (will throw an error)';
          }
        }
        prev = range.upper;
        if (range.upper && range.lower && range.upper <= range.lower) {
          valid = false;
          error = 'Column (Structure): Upper bound should be larger than lower bound.';
        }
        if (range.amount && range.amount < 0) {
          valid = false;
          error = 'Column (Structure): Negative amount should not be allowed. Example Format: 0-4=50000;4-10=100000';
        }
      }
    });

    return {
      valid,
      error,
      // eslint-disable-next-line
      row: incentive.__rowNum__,
    };
  };

  const validateJSON = (data: any) => {
    // validate keys
    let errorsList: any[] = [];
    const EXPECTED_COLUMNS = ['Prefecture', 'City', 'Program Name', 'Type', 'Start Date', 'End Date', 'Structure', 'Max', 'Url'];
    if (data.length === 0) {
      errorsList.push({
        valid: false,
        error: 'Could not read data from file or file is empty. If the file is not empty please inform the Dev team.',
        row: 0,
      });
      return {
        valid: false,
        errorsList,
      };
    }

    EXPECTED_COLUMNS.forEach((k) => {
      if (data[0][k] === undefined) {
        errorsList.push({
          valid: false,
          error: `Missing Column "${k}".`,
          row: 0,
        });
      }
    });

    // Can possible remove the Unexpected Column Check. Leaving in to be more strict for now.
    Object.keys(data[0]).forEach((k) => {
      if (EXPECTED_COLUMNS.includes(k) === false) {
        errorsList.push({
          valid: false,
          error: `Unexpected Column "${k}".`,
          row: 0,
        });
      }
    });

    if (errorsList.length !== 0) {
      return {
        valid: false,
        errorsList,
      };
    }

    // validate row data
    const validateCheck = data.map((incentive: any) => {
      if (incentive.Type.toLowerCase() === 'percentofprice') return validatePercentage(incentive);
      if (incentive.Type.toLowerCase() === 'yenperkw') return validateRangeString(incentive);
      if (incentive.Type.toLowerCase() === 'fixedyen') return validateRangeString(incentive);
      return {
        valid: false,
        error: 'Column (Type): invalid. Expected "PercentOfPrice", "YenPerKW", or "FixedYen"',
        // eslint-disable-next-line
        row: incentive.__rowNum__,
      };
    });
    errorsList = [...errorsList, ...validateCheck.filter((row: any) => row.valid === false)];
    const valid = errorsList.length === 0;
    return {
      valid,
      errorsList,
    };
  };

  const handleFileChange = (event:any) => {
    event.persist();
    setErrors([]);
    setOpenErrors(false);
    const { files } = event.target;
    const f = files[0];
    if (f) {
      setFileName(f.name);
    } else {
      setFileName('');
      return;
    }
    const reader = new FileReader();
    reader.onloadend = (e: any) => {
      // clearErrors if they exist.
      // const data = new Uint8Array(e.target.result);
      const wb = XLSX.read(e.target.result, { type: 'string', raw: true });
      const jsonData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: true });
      const { valid, errorsList } = validateJSON(jsonData);
      if (valid) {
        setRequestJson({ incentives: jsonData });
      } else {
        // setErrors
        setErrors(errorsList);
        setOpenErrors(true);
        // showErrors
      }
    };

    reader.onerror = (e: any) => {
      console.error('File read error.', e);
    };
    reader.readAsText(f);
  };

  const handleSendClick = async () => {
    if (errors.length === 0) {
      const response = await uploadIncentives(requestJson);
      if (response.status === 200) {
        setOpen(false);
        setIncentives([]);
        setIsLoading(true);
        const { data } = await getIncentives();
        setIncentives(data.map((incentive: any) => {
          return { ...incentive, filterField: JSON.stringify(Object.values(incentive)) };
        }));
        createSelectOptions(data);
        setIsLoading(false);
      } else {
        setErrors([{
          valid: false,
          error: '500 Server Error: Upload Failed.',
          row: 0,
        }]);
        setOpenErrors(true);
      }
    }
  };

  return (
    <PageTemplate pageTitle="Incentives">
      <Sheet>
        <div className={classes.controlsRow}>
          <FormControl className={classes.select}>
            <InputLabel htmlFor="prefecture-select">Prefecture</InputLabel>
            <Select
              onChange={filterIncentivesByPrefecture}
              id="prefecture-select"
              name="prefecture"
              value={selectedPrefecture ? selectedPrefecture.value : ''}
            >
              <MenuItem />
              {selectOptions.map((area) => <MenuItem value={area.value} key={area.value}>{area.label}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="installer-filter">Search</InputLabel>
            <Input id="installer-filter" onChange={filterIncentives} />
          </FormControl>
          {(role === 'admin' || role === 'staff') && (
            <Button
              variant="contained"
              className={`${classes.buttonSecondary} ${classes.importButton}`}
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Import
            </Button>
          )}
          {/* <CreateButton to="/installers/new"> */}
        </div>
        <PaginatedTable
          columns={[
            {
              Header: 'Prefecture',
              accessor: 'prefecture',
              id: 'prefecuture', // id is used for React key prop when creating the table
            },
            {
              Header: 'City',
              accessor: 'city',
              id: 'city',
            },
            {
              Header: 'Program Name',
              accessor: 'name',
              Cell: (opts: any) => (
                opts.cell.value ? <div className={classes.nameCell}>{opts.cell.value}</div> : <div />
              ),
              id: 'name',
            },
            {
              Header: 'Logic',
              accessor: 'type',
              id: 'type',
            },
            {
              Header: 'Info',
              Cell: ({ row, cell }: any) => (
                <div className={classes.structureCell}>
                  { row.original.type === 'PercentOfPrice' && <span>Percent: {row.original.structure}<br /></span>}
                  { row.original.type !== 'PercentOfPrice'
                    && row.original.structure.map((s: any) => {
                      return (<div key={JSON.stringify(s)}>{s.from}kW - {s.to}kW : {s.value}円<br /></div>);
                    })}
                  <div>Max: {row.original.max}</div>
                </div>
              ),
              accessor: 'structure',
              id: 'structure',
            },
            {
              Header: 'Start Date',
              accessor: 'startDate',
              id: 'startDate',
            },
            {
              Header: 'End Date',
              accessor: 'endDate',
              id: 'endDate',
            },
          ]}
          data={useFilteredIncentives ? filteredIncentives : incentives}
          totalRows={totalIncentives}
          url="incentives"
          context={IncentivesTableContext}
        />
        {isLoading && <PendingBar />}
      </Sheet>
      {/* Start Upload Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-delete-staff"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select a file to upload.<br />
            <span className={classes.makeRed}>Warning</span>: This will replace all existing incentives
          </DialogContentText>
          <div className={`${classes.fileInput} ${classes.mgTop}`}>
            <input
              accept=".csv,.xlsx"
              className={classes.mgTop}
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={handleFileChange}
            />
            {/* eslint-disable-next-line */}
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span" className={classes.button}>
                Select
              </Button>
            </label>
            <Typography className={classes.fileText}>{ fileName }</Typography>
          </div>
          <FormHelperText id="my-helper-text">Supports: xlsx and csv file types.</FormHelperText>
          {errors.length !== 0 && <FormHelperText id="my-helper-text" className={classes.makeRed}>Fix errors with this file before upload.</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendClick} className={classes.makeRed} disabled={role === 'readonly' || errors.length !== 0 || fileName === ''}>
            Replace
          </Button>
          {errors.length !== 0 && <Button onClick={handleOpenErrors} color="primary">Open Errors</Button>}
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Upload Dialog */}
      {/* Start Error Dialog */}
      <Dialog fullScreen scroll="paper" open={openErrors} onClose={handleCloseErrors}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Button onClick={handleCloseErrors} className={classes.button}>
              Close Error Screen
            </Button>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <List>
          {errors.map((err) => {
            return (
              <React.Fragment key={JSON.stringify(err)}>
                <ListItem>
                  <ListItemText primary={`Error: ${err.error}`} secondary={`Row: ${err.row}`} />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </Dialog>
      {/* End Error Dialog */}
    </PageTemplate>
  );
};

export default withIncentivesTableContextProvider(IncentivesIndex);
