import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import PageTemplate from '../../PageTemplate';
import { Sheet } from '../../../components';
import useAxios from '../../../lib/hooks/useAxios';
import { RefundFormValues } from '../../../types/Refund';
import RefundsForm from '../components/RefundsForm/RefundsForm';

const defaultRefund: RefundFormValues = {
  lead_id: null,
  reason: 'INVALID_NAME',
  reason_details: '',
  status_details: '',
  note: '',
  created_at: new Date(),
  closed_at: null,
  status: 1,
};

const RefundsNew: React.FC = () => {
  const [refund, setRefund] = useState<RefundFormValues>(defaultRefund);
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const { getRefundForLead, createRefund } = useAxios();
  const history = useHistory();
  const { leadId } = queryString.parse(history.location.search);

  useEffect(() => {
    const fetchRefund = async () => {
      if (leadId) {
        const response = await getRefundForLead(Number(leadId));
        if (response && response.status === 200 && response.data) {
          history.replace(`/refunds/${response.data.refund_id}/edit`);
        }
      }
    };

    if (leadId) {
      setRefund({ ...refund, lead_id: Number(leadId) });
    }

    fetchRefund();
  }, [leadId]);

  const submitRefund = async (values: RefundFormValues) => {
    if (values) {
      setRefund({ ...values });
      if (submitMessage) {
        setSubmitMessage(null);
      }

      const response = await createRefund(values);
      if (response.status === 200) {
        setSubmitMessage('Successfully created the refund!');
        return true;
      }
      setSubmitMessage(
        'Something went wrong, please try to submit again.',
      );
      return false;
    }
    return null;
  };

  return (
    <PageTemplate pageTitle="New Refund">
      <Sheet>
        <RefundsForm
          refund={refund}
          submitRefund={submitRefund}
          submitMessage={submitMessage}
          setSubmitMessage={setSubmitMessage}
          newRefund={true}
          billingCycleId={null}
        />
      </Sheet>
    </PageTemplate>
  );
};

export default RefundsNew;
