import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { PendingBar } from '../../../../components';
import { Installer } from '../../../../types/Installer';

const useStyles = makeStyles(() => ({
  installerField: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  installerFieldWrap: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  select: {
    flexShrink: 0,
    minWidth: 340,
  },
  removeButton: {
    color: 'red',
  },
}));

type InstallerFieldProps = {
  installerIds: (number)[];
  installers: Installer[];
  onChange: (data: (number)[]) => void;
  fetchingInstallers: boolean;
  disabled: boolean;
};

const InstallerField:React.FC<InstallerFieldProps> = ({ installers, installerIds, onChange, fetchingInstallers, disabled }) => {
  const classes = useStyles();
  const [values, setValues] = useState<(number)[]>([...installerIds]);
  const [emptyInstallers, setEmptyInstallers] = useState<null[]>([]);

  const handleChange = (oldValue: number | null) => (event: React.ChangeEvent<{ name?: string | undefined; value: any;}>) => {
    let newValues;
    if (oldValue) {
      newValues = values.map((value) => (value === oldValue ? event.target.value : value));
    } else {
      newValues = [...values, event.target.value];
    }
    if (emptyInstallers.length) {
      setEmptyInstallers([...emptyInstallers].slice(1));
    }
    onChange(newValues);
    setValues(newValues);
  };

  const onRemove = (installerId: number | null) => () => {
    if (installerId === null) {
      setEmptyInstallers([...emptyInstallers].slice(1));
    } else {
      const newValues = values.filter((value) => Number(value) !== Number(installerId));
      onChange(newValues);
      setValues(newValues);
    }
  };

  const addInstaller = () => {
    setEmptyInstallers([...emptyInstallers, null]);
  };

  const getInstallerOptions = (installerId: number | null) => {
    const idsToFilter = values.filter((id) => id !== installerId);
    return installers.filter((installer) => installer.active && !idsToFilter.includes(installer.installer_id)).map((installer) => {
      return (
        <MenuItem value={Number(installer.installer_id)} key={installer.installer_id}>{installer.companyName}</MenuItem>
      );
    });
  };

  return (
    <>
      {values.map((installerId, index) => (
        <div className={classes.installerField} key={installerId}>
          <FormControl>
            <div className={classes.installerFieldWrap}>
              <Select
                onChange={handleChange(installerId)}
                name={`installer${index}`}
                value={Number(installerId)}
                className={classes.select}
                disabled={fetchingInstallers || disabled}
              >
                {getInstallerOptions(installerId)}
              </Select>
              <Button
                className={classes.removeButton}
                onClick={onRemove(installerId)}
                startIcon={<DeleteIcon />}
                disabled={fetchingInstallers || disabled || values.length === 1}
              >
                Remove
              </Button>
            </div>
          </FormControl>
        </div>
      ))}
      {emptyInstallers.map((installerId, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={classes.installerField} key={`${new Date().getTime()}${index}`}>
          <FormControl>
            <div className={classes.installerFieldWrap}>
              <Select
                onChange={handleChange(installerId)}
                name={`installer${index}`}
                value={Number(installerId)}
                className={classes.select}
                disabled={fetchingInstallers}
              >
                {getInstallerOptions(installerId)}
              </Select>
              <Button
                className={classes.removeButton}
                onClick={onRemove(installerId)}
                startIcon={<DeleteIcon />}
                disabled={fetchingInstallers}
              >
                Remove
              </Button>
            </div>
          </FormControl>
        </div>
      ))}
      <div>
        <Button
          // className={classes.addServiceButton}
          onClick={addInstaller}
          startIcon={<AddIcon />}
          disabled={emptyInstallers.length + values.length >= 3}
        >
          Add Installer
        </Button>
      </div>
      {fetchingInstallers && <PendingBar />}
    </>
  );
};

export default InstallerField;
