import formatDate from './formatDate';

const calcDueDate = (date: string | Date) => {
  const result = new Date(date);
  const formattedDate = typeof date === 'string' ? new Date(date) : date;
  if (formattedDate.getDate() > 20) {
    result.setMonth(formattedDate.getMonth() + 2);
  } else {
    result.setMonth(formattedDate.getMonth() + 1);
  }
  result.setDate(20);
  return formatDate(result);
};

export default calcDueDate;
