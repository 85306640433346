import { useEffect, useState, useContext } from 'react';
import BillingCycleContext from '../context/BillingCycleContext';
import { BillingCycle } from '../../types/BillingCycle';
import useAxios from './useAxios';

const useBillingCycles = (): {isLoading: boolean, billingCycles: BillingCycle[]} => {
  const { getBillingCycles } = useAxios();
  const { state: { billingCycles }, dispatch } = useContext(BillingCycleContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBillingCycles = async () => {
      // isLoading is only for initial page load, if there are no billing cycles in context
      if (billingCycles.length === 0) setIsLoading(true);
      // fetch and set the billing cycles from the server, in case the data is stale
      const { data: fetchedBillingCycles } = await getBillingCycles();
      dispatch({ type: 'set', payload: { billingCycles: fetchedBillingCycles } });
      setIsLoading(false);
    };

    fetchBillingCycles();
  }, []);

  return {
    isLoading,
    billingCycles,
  };
};

export default useBillingCycles;
