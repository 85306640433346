import React, { useState, useContext } from 'react';
import InstallerContext from '../../../lib/context/InstallersContext';
import useAxios from '../../../lib/hooks/useAxios';
import { Installer, InstallerFormData } from '../../../types/Installer';
import InstallersForm from './InstallersForm';
import formDataFromValues from '../utils/formDataFromValues';

type InstallersEditProps = {
  installerId: string;
  installer: Installer;
};

const InstallersEdit: React.FC<InstallersEditProps> = ({ installer, installerId }) => {
  const { state: { installers }, dispatch } = useContext(InstallerContext);
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const { updateInstaller } = useAxios();

  const submitInstaller = (values: InstallerFormData) => {
    if (values && installerId) {
      if (submitMessage) {
        setSubmitMessage(null);
      }
      const formData = formDataFromValues(values);

      return updateInstaller(formData, installerId)
        .then((response) => {
          if (response.status === 200) {
            const updatedInstallers = installers.map((oldInstaller) => {
              if (oldInstaller.installer_id === Number(installerId)) {
                return response.data;
              }
              return oldInstaller;
            });
            dispatch({ type: 'set', payload: { installers: updatedInstallers } });
            setSubmitMessage('Successfully updated the installer!');
          } else {
            setSubmitMessage(
              'Something went wrong, please try to submit again.',
            );
          }
        });
    }
    return null;
  };

  return (
    <InstallersForm
      installer={installer}
      submitInstaller={submitInstaller}
      submitMessage={submitMessage}
      setSubmitMessage={setSubmitMessage}
      cancelLinkUrl={`/installers/${installerId}`}
    />
  );
};

export default InstallersEdit;
