import React from 'react';
import Typography from '@material-ui/core/Typography';
import Invoice from './Invoice/Invoice';
import InstallerCurrentBillingCycle from './InstallerCurrentBillingCycle';
import { Installer } from '../../../types/Installer';

type InstallerDetailsProps = {
  installerId: string;
  installer: Installer;
};

const InstallerDetails: React.FC<InstallerDetailsProps> = ({ installerId, installer }) => {
  return (
    <>
      <Typography variant="h5">Current Billing Cycle</Typography>
      <InstallerCurrentBillingCycle installerId={installerId} installer={installer} />
      <br /><br />
      <Typography variant="h5">Invoices</Typography>
      <Invoice />
    </>
  );
};

export default InstallerDetails;
