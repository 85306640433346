import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ServiceArea } from '../../../types/ServiceArea';
import { getServiceArea } from '../../../lib/utils/serviceAreas';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    width: '100%',
  },
  cell: {
    width: 120,
    padding: '4px 8px',
    '& + $cell': {
      marginLeft: 24,
    },
  },
  header: {
    borderBottom: '1px solid lightgray',
    textAlign: 'center',
  },
}));

type ServiceAreaProps = {
  serviceAreas: ServiceArea[];
};

const ServiceAreaList:React.FC<ServiceAreaProps> = ({ serviceAreas }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.row}>
        <div className={clsx(classes.cell, classes.header)}>- Prefecture -</div>
        <div className={clsx(classes.cell, classes.header)}>- Max Leads -</div>
        <div className={clsx(classes.cell, classes.header)}>- Services -</div>
      </div>
      {
        serviceAreas.map((area) => (
          <div key={area.name} className={classes.row}>
            <div className={classes.cell}>{getServiceArea(area.name)}</div>
            <div className={classes.cell}>{area.maxLeads}</div>
            <div className={classes.cell}>{area.installation && 'Installation'}{area.ppa && ', PPA'}</div>
          </div>
        ))
      }
    </>
  );
};

export default ServiceAreaList;
