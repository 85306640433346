/* eslint-disable import/prefer-default-export */
type Colors = 'white'
| 'gray'
| 'borderColor'
| 'darkGray'
| 'textColor'
| 'orange'
| 'orangeHover'
| 'purple'
| 'purpleHover'
| 'bgBlue'
| 'black'
| 'bgGray';

export const colors: {[key in Colors ]: string} = {
  white: '#fff',
  gray: '#ececec',
  borderColor: '#dedede',
  darkGray: '#2b2b2b',
  textColor: '#2b2b2b',
  orange: '#e99413',
  orangeHover: '#e7b829',
  purple: '#5a3087',
  purpleHover: '#4d2777',
  bgBlue: '#f3fbfd',
  black: '#000',
  bgGray: '#f8f8f8',
};

// global breakpoints
const BP1 = 768;
const BP2 = 995;
const SM_MAX = BP1 - 1;
const MD_MIN = BP1;
const MD_MAX = BP2 - 1;
const LG_MIN = BP2;

// media query strings
const smOnly = `max-width: ${SM_MAX}px`;
const mdMin = `min-width: ${MD_MIN}px`;
const mdMax = `max-width: ${MD_MAX}px`;
const lgOnly = `min-width: ${LG_MIN}px`;

type Breakpoints = 'smOnly' | 'mdOnly' | 'mdDown' | 'mdUp' | 'lgOnly';

export const mediaQueries: {[key in Breakpoints]: string} = {
  smOnly: `@media (${smOnly})`,
  mdOnly: `@media (${mdMin}) and (${mdMax})`,
  mdDown: `@media (${mdMax})`,
  mdUp: `@media (${mdMin})`,
  lgOnly: `@media (${lgOnly})`,
};
