import { useEffect, useState, useContext } from 'react';
import useAxios from './useAxios';
import IntroductionContext from '../context/IntroductionContext';
import InstallersContext from '../context/InstallersContext';
import formatIntro from '../utils/formatIntro';

const useIntroduction = (introId: string | number | undefined) => {
  const { state: { introductions } } = useContext(IntroductionContext);
  const { state: { installers }, dispatch } = useContext(InstallersContext);
  const { getIntroduction, getInstallers } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [intro, setIntro] = useState();

  useEffect(() => {
    const fetchIntroduction = async () => {
      if (introId) {
        const currentIntroduction = introductions.find((i) => Number(introId) === Number(i.intro_id));
        // Set the intro found in context for initial page load
        if (currentIntroduction) setIntro(currentIntroduction);
        // isLoading is only for initial page load, if there is no refund in context
        if (!currentIntroduction) setIsLoading(true);
        // fetch and set the refund from the server, in case the data is stale
        const { data: fetchedIntroduction } = await getIntroduction(introId);
        setIntro(formatIntro(fetchedIntroduction));
        if (!currentIntroduction) setIsLoading(false);
        if (installers.length === 0) {
          // Introductions need installers, fetch them again in case the data is stale
          const { data: fetchedInstallers } = await getInstallers();
          dispatch({ type: 'set', payload: { installers: fetchedInstallers } });
        }
      }
    };

    fetchIntroduction();
  }, [introId]);

  return {
    isLoading,
    intro,
  };
};

export default useIntroduction;
