import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import { Lead } from '../../types/Lead';

const useLead = (leadId: string | number | undefined | null) => {
  const { getLead } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [lead, setLead] = useState<Lead>();

  useEffect(() => {
    const fetchLead = async () => {
      if (leadId) {
        setIsLoading(true);
        const { data: fetchedLead } = await getLead(leadId);
        setIsLoading(false);
        setLead(fetchedLead);
      }
    };

    fetchLead();
  }, [leadId]);

  return {
    isLoading,
    lead,
  };
};

export default useLead;
