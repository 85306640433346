import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import useInstaller from '../../../../lib/hooks/useInstaller';
import useIntroduction from '../../../../lib/hooks/useIntroduction';
import useAxios from '../../../../lib/hooks/useAxios';
import useLead from '../../../../lib/hooks/useLead';
import { TextInput, SuncleButton, PendingBar, CancelButton, PendingSpinner, InfoDetail } from '../../../../components';
import { RefundFormValues } from '../../../../types/Refund';

type FormInputNames = 'lead_id' | 'reason' | 'reason_details' | 'status' | 'status_details' | 'note';

const useStyles = makeStyles(() => ({
  select: {
    maxWidth: 180,
  },
}));

const copyRefund = (refund: RefundFormValues) => {
  if (refund) {
    return {
      lead_id: Number(refund.lead_id),
      reason: refund.reason,
      reason_details: refund.reason_details || '',
      status_details: refund.status_details || '',
      note: refund.note || '',
      created_at: refund.created_at,
      closed_at: refund.closed_at,
      status: Number(refund.status),
    };
  }
  return refund;
};

type Status = {
  status_id: number;
  status: 'OPEN' | 'VALID' | 'INVALID';
};

type RefundsFormProps = {
  refund: RefundFormValues;
  submitRefund: (values: RefundFormValues) => Promise<boolean | null>;
  submitMessage: string | null;
  setSubmitMessage: (message: string | null) => void;
  newRefund: boolean;
  billingCycleId: number | null;
};

const RefundsForm: React.FC<RefundsFormProps> = ({ refund, submitRefund, submitMessage, setSubmitMessage, newRefund, billingCycleId }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [statuses, setStatuses] = useState<Status[]>();
  const [values, setValues] = useState<RefundFormValues>(copyRefund(refund));
  const { lead } = useLead(values && values.lead_id);
  const { installer } = useInstaller(values && values.installer_id);
  const { intro } = useIntroduction(values && values.intro_id);
  const { getStatusesForTable, getRefundForLead } = useAxios();
  const history = useHistory();
  const { leadId } = queryString.parse(history.location.search);
  const classes = useStyles();

  useEffect(() => {
    const fetchStatuses = async () => {
      const response = await getStatusesForTable('refund');
      if (response && response.status === 200) {
        setStatuses(response.data);
      }
    };

    fetchStatuses();

    if (!billingCycleId) {
      setFormDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (refund) {
      const newValues = copyRefund(refund);
      setValues(newValues);
    }
  }, [refund]);

  useEffect(() => {
    if (lead) {
      setValues({ ...values, installer_id: lead.installer_id, intro_id: lead.intro_id });
    }
  }, [lead]);

  const checkForExistingRefund = async (leadIdForRefund: string) => {
    if (leadIdForRefund.length >= 5) {
      setFormDisabled(true);
      const response = await getRefundForLead(Number(leadIdForRefund));
      if (response && response.status === 200 && response.data) {
        setSubmitMessage('There is already a refund for this Lead. Please enter a different Lead ID');
      } else {
        setFormDisabled(false);
      }
    }
  };


  const handleChange = (name: FormInputNames) => async (event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: any; checked?: boolean | string; files?: any }>) => {
    if (submitMessage) {
      setSubmitMessage(null);
    }
    if (values) {
      setValues({ ...values, [name]: event.target.value });
    }
    if (newRefund && name === 'lead_id') {
      await checkForExistingRefund(event.target.value);
    }
  };

  const handleSelectChange = (name: FormInputNames) => (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => {
    if (submitMessage) {
      setSubmitMessage(null);
    }
    setValues({ ...values, [name]: event.target.value });
  };

  const onDateChange = (date: Date | null) => {
    if (submitMessage) {
      setSubmitMessage(null);
    }
    setValues({ ...values, created_at: date });
  };

  const handleFormSubmit = async () => {
    if (values && !formSubmitting && !formDisabled) {
      setFormSubmitting(true);
      const success = await submitRefund(values);
      if (success && (values.status === 3 || values.status === 4)) {
        setFormDisabled(true);
      }
      setFormSubmitting(false);
    }
  };

  return (
    <>
      {newRefund && (
        <>
          <TextInput
            labelText="Lead ID"
            name="lead_id"
            value={values.lead_id || ''}
            onChange={handleChange('lead_id')}
            fullWidth={true}
          />
          <Typography>Introduction: {intro
            ? <Link to={`/introductions/${intro.intro_id}`}>{intro.intro_id}</Link>
            : ((lead || leadId) && <PendingSpinner />)}
          </Typography>
          <Typography>Installer: {installer
            ? <Link to={`/installers/${installer.installer_id}`}>{installer.companyName}</Link>
            : ((lead || leadId) && <PendingSpinner />)}
          </Typography>
        </>
      )}
      {!newRefund && (
        <>
          <InfoDetail label="Lead ID: " data={refund ? refund.lead_id : ''} />
          <InfoDetail label="Introduction: ">
            {intro ? <Link to={`/introductions/${intro.intro_id}`}>{intro.intro_id}</Link> : <PendingSpinner />}
          </InfoDetail>
          <InfoDetail label="Installer: ">
            {installer ? <Link to={`/installers/${installer.installer_id}`}>{installer.companyName}</Link> : <PendingSpinner />}
          </InfoDetail>
        </>
      )}
      <FormControl>
        <InputLabel htmlFor="reason-select">Reason</InputLabel>
        <Select
          className={classes.select}
          value={values.reason}
          onChange={handleSelectChange('reason')}
          id="reason-select"
          name="reason"
          disabled={formDisabled || !statuses}
        >
          <MenuItem value="INVALID_NAME">Invalid Name</MenuItem>
          <MenuItem value="INVALID_CONTACT">Invalid Contact</MenuItem>
          <MenuItem value="REPEAT_LEAD">Repeat Lead</MenuItem>
          <MenuItem value="COMPETITION">Competition</MenuItem>
          <MenuItem value="INDUSTRIAL">Industrial</MenuItem>
          <MenuItem value="OTHER">Other (explain)</MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="status-select">Status</InputLabel>
        <Select
          className={classes.select}
          value={values.status}
          onChange={handleSelectChange('status')}
          id="status-select"
          name="status"
          disabled={formDisabled}
        >
          {statuses && statuses.map((status) => (
            <MenuItem key={status.status_id} value={status.status_id}>{status.status}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextInput
        labelText="Reason Details (Installer reason for making claim)"
        name="reason_details"
        value={values.reason_details}
        onChange={handleChange('reason_details')}
        fullWidth={true}
        multiline={true}
        disabled={formDisabled}
      />
      <TextInput
        labelText="Status Details (Explanation sent to installer)"
        name="status_details"
        value={values.status_details}
        onChange={handleChange('status_details')}
        fullWidth={true}
        multiline={true}
        disabled={formDisabled}
      />
      <TextInput
        labelText="Notes (***INTERNAL USE ONLY***)"
        name="note"
        value={values.note}
        onChange={handleChange('note')}
        fullWidth={true}
        multiline={true}
        disabled={formDisabled}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.select}
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          margin="normal"
          id="date-picker-inline"
          label="Claim Date"
          value={values.created_at}
          onChange={onDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disabled={formDisabled}
        />
      </MuiPickersUtilsProvider>
      <div>
        <SuncleButton onClick={handleFormSubmit} disabled={formSubmitting || formDisabled}>Submit</SuncleButton>
        <CancelButton />
      </div>
      {submitMessage && <p>{submitMessage}</p>}
      {formSubmitting && <PendingBar />}
    </>
  );
};

export default RefundsForm;
