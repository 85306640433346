/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { useTable, useSortBy, usePagination } from 'react-table';
import { TablePaginationActions } from '../../components';

type TableProps = {
  columns: any;
  data: any;
  totalRows?: number;
};

const DashboardTable: React.FC<TableProps> = ({ columns, data, totalRows }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const {
    getTableProps, headerGroups, prepareRow, page, gotoPage, setPageSize, state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 100 },
    },
    useSortBy,
    usePagination,
  );

  const headerCell = (column: any) => {
    return (
      <TableCell {...column.getHeaderProps()}>
        {column.render('Header')}
      </TableCell>
    );
  };

  const headerRow = (headerGroupsArg: any) => headerGroupsArg.map((headerGroup: any) => (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column: any) => headerCell(column))}
    </TableRow>
  ));

  const tableRows = (rows: any[]) => rows.map((row: any) => {
    prepareRow(row);
    return (
      <TableRow {...row.getRowProps()}>
        {row.cells.map((cell: any) => (
          <TableCell {...cell.getCellProps()}>
            {cell.render('Cell')}
          </TableCell>
        ))}
      </TableRow>
    );
  });

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(Number(event.target.value));
    gotoPage(0);
  };

  return (
    <Table {...getTableProps()}>
      <TableHead>{headerRow(headerGroups)}</TableHead>
      <TableBody>{tableRows(page)}</TableBody>
      {totalRows && (
      <TableFooter>
        <TableRow>
          <TablePagination
            colSpan={columns.length}
            count={Number(totalRows)}
            rowsPerPage={pageSize}
            page={currentPage}
            rowsPerPageOptions={[10, 50, 100, { label: 'All', value: -1 }]}
            onChangePage={(event, newPage) => { gotoPage(newPage); setCurrentPage(newPage); }}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
      )}
    </Table>
  );
};

export default DashboardTable;
