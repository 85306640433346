import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl } from '@material-ui/core';
import { TextInput, SuncleButton, PendingBar, CancelButton } from '../../../components';
import { Staff } from '../../../types/Staff';

type FormInputNames = 'name' | 'email' | 'role';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 8,
    minWidth: 120,
  },
  formInput: {
    display: 'block',
    maxWidth: 520,
    marginTop: 8,
  },
  cancel: {
    backgroundColor: '#e59924',
    '&:hover': {
      backgroundColor: '#e59924',
    },
    marginLeft: 24,
  },
  formRow: {
    display: 'flex',
  },
  nameField: {
    marginLeft: 16,
  },
  switchLabel: {
    marginLeft: 0,
  },
  addServiceButton: {
    marginTop: 16,
  },
  imageContainer: {
    marginBottom: 8,
  },
}));

type StaffFormProps = {
  staff: Staff
  submitStaff: (values: Staff) => void;
  submitMessage: string | null;
  setSubmitMessage: (message: string | null) => void;
  cancelLinkUrl: string;
};

const copyStaff = (staff: Staff) => {
  if (staff) {
    return { ...staff };
  }
  return staff;
};

const StaffForm: React.FC<StaffFormProps> = ({ staff, submitStaff, submitMessage, setSubmitMessage, cancelLinkUrl }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [values, setValues] = useState<Staff | undefined>();
  const classes = useStyles();

  useEffect(() => {
    if (staff) {
      setValues(copyStaff(staff));
    }
  }, [staff]);

  const handleChange = (name: FormInputNames) => (event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: any; checked?: boolean | string; files?: any }>) => {
    if (submitMessage) {
      setSubmitMessage(null);
    }
    if (values) {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const handleFormSubmit = async () => {
    if (values && !formSubmitting) {
      setFormSubmitting(true);
      await submitStaff(values);
      setFormSubmitting(false);
    }
  };

  return (
    <>
      <TextInput
        labelText="Full Name"
        name="name"
        value={values ? values.name : ''}
        onChange={handleChange('name')}
        fullWidth={true}
      />
      <TextInput
        labelText="Email"
        name="email"
        value={values ? values.email : ''}
        onChange={handleChange('email')}
        fullWidth={true}
      />
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="role-select-label">Role</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={values ? values.role : ''}
            onChange={handleChange('role')}
          >
            <MenuItem value="readonly">Read Only</MenuItem>
            <MenuItem value="staff">Staff</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <SuncleButton onClick={handleFormSubmit} disabled={formSubmitting}>Submit</SuncleButton>
        <CancelButton />
      </div>
      {submitMessage && <p>{submitMessage}</p>}
      {formSubmitting && <PendingBar />}
    </>
  );
};

export default StaffForm;
