/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';

type ProtectedRouteProps = {
  component: React.FC;
  path: string;
  condition: boolean;
  exact?: boolean;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  path,
  condition,
  ...rest
}) => {
  const render = (props: any) => (condition ? <Component {...props} /> : null);

  return <Route path={path} render={render} {...rest} />;
};

export default ProtectedRoute;
