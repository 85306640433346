import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const FlexRow: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.flexRow}>{children}</div>;
};

export default FlexRow;
