import React, { createContext, useReducer } from 'react';

type RoleContextState = {
  role: 'readonly' | 'staff' | 'admin';
};

type RoleContextAction = {
  type: 'set',
  payload: {
    role: 'readonly' | 'staff' | 'admin';
  }
};

type RoleContextArgs = {
  state: RoleContextState;
  dispatch: React.Dispatch<RoleContextAction>;
};

const initialState: RoleContextState = { role: 'readonly' };

function reducer(state: RoleContextState, action: RoleContextAction) {
  switch (action.type) {
    case 'set':
      return { role: action.payload.role };
    default:
      return state;
  }
}

const RoleContext = createContext({} as RoleContextArgs);

export const RoleContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  RoleContext.displayName = 'RoleContext';
  return (
    <RoleContext.Provider value={value}>{children}</RoleContext.Provider>
  );
};

export default RoleContext;
