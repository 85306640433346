import { InstallerFormData } from '../../../types/Installer';

const formDataFromValues = (values: InstallerFormData) => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => {
    if (key === 'serviceArea') {
      const cleanedServiceAreas = values.serviceArea.filter((area) => area.name !== '' && area.maxLeads !== 0 && (area.installation || area.ppa));
      formData.append(key, JSON.stringify(cleanedServiceAreas));
    } else if (key === 'emailForLeads' || key === 'areaStat') {
      formData.append(key, JSON.stringify(values[key]));
    } else {
      formData.append(key, values[key]);
    }
  });

  return formData;
};

export default formDataFromValues;
