import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useAxios from '../../../lib/hooks/useAxios';
import formatDate from '../../../lib/utils/formatDate';
import { Error, PendingBar } from '../../../components';

const useStyles = makeStyles((theme) => ({
  tableText: {
    whiteSpace: 'nowrap',
  },
  tableTitle: {
    border: 'none',
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    outline: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      color: '#5d2f91',
    },
  },
  tableTitleIcon: {
    color: '#5d2f91',
  },
  tableTitleText: {
    marginTop: 7,
  },
  collapseBody: {
    overflowX: 'auto',
  },
  invoiceDownload: {
    minWidth: 48,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  invoiceDownloadIcon: {
    color: '#5d2f91',
  },
}));

type Summary = {
  intro_id: number;
  family_name: string;
  given_name: string;
  email: string;
  status: string;
};

type CustomerRequestDetailsProps = {
  isDefaultOpen?: boolean;
  billingCycleId: number;
  runDate: Date;
};

const CustomerRequestDetails: React.FC<CustomerRequestDetailsProps> = ({ isDefaultOpen, billingCycleId, runDate }) => {
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { getIntrosForBillingCycle } = useAxios();
  const [summaryData, setSummaryData] = useState<Summary[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (isDefaultOpen) {
      setIsOpen(true);
    }
  }, []);

  const getSummaryData = async () => {
    if (summaryData.length === 0) {
      setIsDownloading(true);
      const { data, status } = await getIntrosForBillingCycle(billingCycleId);
      if (status === 200) {
        setSummaryData(data);
      } else {
        setShouldDisplayError(true);
      }
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Button
        className={clsx(classes.tableTitle)}
        onClick={() => {
          setIsOpen(!isOpen);
          getSummaryData();
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          gutterBottom
          className={classes.tableTitleText}
        >
          {formatDate(runDate)} Summary
        </Typography>
        {!isOpen && (
          <KeyboardArrowDownIcon
            fontSize="large"
            className={classes.tableTitleIcon}
          />
        )}
        {isOpen && (
          <KeyboardArrowUpIcon
            fontSize="large"
            className={classes.tableTitleIcon}
          />
        )}
      </Button>
      <Collapse in={isOpen} className={clsx(isOpen && classes.collapseBody)}>
        {isDownloading && <PendingBar />}
        {shouldDisplayError && <Error shouldDisplay={shouldDisplayError} />}
        {summaryData.length > 0 && (
          <>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Intro ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryData.map((row) => (
                  <TableRow key={row.intro_id}>
                    <TableCell><Link to={`/introductions/${row.intro_id}`}>{row.intro_id}</Link></TableCell>
                    <TableCell>{row.family_name} {row.given_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </Collapse>
      <Divider />
    </>
  );
};

export default CustomerRequestDetails;
