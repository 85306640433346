import React, { createContext, useReducer } from 'react';

type TableState = {
  currentPage: number;
};

type TableAction = {
  type: 'goTo',
  payload: {
    nextPage: number;
  }
};

type ContextArgs = {
  state: TableState;
  dispatch: React.Dispatch<TableAction>;
};

export const initialState: TableState = { currentPage: 0 };

export function reducer(state: TableState, action: TableAction) {
  switch (action.type) {
    case 'goTo':
      return { currentPage: action.payload.nextPage };
    default:
      return state;
  }
}

const RefundsTableContext = createContext({} as ContextArgs);

export const RefundsTableContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  RefundsTableContext.displayName = 'RefundsTableContext';
  return (
    <RefundsTableContext.Provider value={value}>{children}</RefundsTableContext.Provider>
  );
};

export const withRefundsTableContextProvider = (Component: React.FC) => () => (
  <RefundsTableContextProvider><Component /></RefundsTableContextProvider>
);

export default RefundsTableContext;
