import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Sheet, PendingBar } from '../../../components';
import BillingCycle from '../components/BillingCycle/BillingCycle';
import PageTemplate from '../../PageTemplate';
import useBillingCycles from '../../../lib/hooks/useBillingCycles';
import useAxios from '../../../lib/hooks/useAxios';

type Summary = {
  company_name: string;
  installer_id: number;
  leads: string;
  refunds: string;
};

const BillingCycleSummaries:React.FC = () => {
  const { isLoading: billingCyclesLoading, billingCycles } = useBillingCycles();
  const { getCurrentBillingCycleSummary } = useAxios();
  const [currentSummaryData, setCurrentSummaryData] = useState<Summary[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCurrentSummary = async () => {
      setIsLoading(true);
      const { data: currentSummary } = await getCurrentBillingCycleSummary();
      setCurrentSummaryData(currentSummary);
      setIsLoading(false);
    };

    fetchCurrentSummary();
  }, []);

  return (
    <PageTemplate pageTitle="Reports">
      <Sheet>
        <Typography variant="h5">Billing Cycle Summaries</Typography>
        <br />
        <Typography>Current Billing Cycle</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Installer Id</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Leads</TableCell>
              <TableCell>Refunds</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentSummaryData && currentSummaryData.map((row) => (
              <TableRow key={row.installer_id}>
                <TableCell>{row.installer_id}</TableCell>
                <TableCell><Link to={`/installers/${row.installer_id}`}>{row.company_name}</Link></TableCell>
                <TableCell>{ row.leads}</TableCell>
                <TableCell>{ row.refunds}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isLoading && <PendingBar /> }
        <List>
          {!billingCyclesLoading && billingCycles.map((bc) => (
            <BillingCycle key={bc.bc_id} billingCycleId={bc.bc_id} runDate={bc.run_date} />
          ))}
        </List>
        {billingCyclesLoading && <PendingBar />}
      </Sheet>
    </PageTemplate>
  );
};

export default BillingCycleSummaries;
