import React from 'react';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formInput: {
    display: 'block',
    maxWidth: 565,
  },
  '& + $formInput': {
    marginTop: 8,
  },
}));

type TextInputProps = {
  labelText: string;
  name: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  value: string | number | boolean | undefined | null;
  fullWidth?: boolean;
  className?: string;
  multiline?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const TextInput: React.FC<TextInputProps> = ({ labelText, name, onChange, value, fullWidth, className, multiline, helperText, disabled }) => {
  const classes = useStyles();
  return (
    <FormControl className={clsx(classes.formInput, className && className)}>
      <TextField
        label={labelText}
        name={name}
        value={value}
        onChange={onChange}
        fullWidth={fullWidth}
        multiline={multiline}
        disabled={disabled}
        helperText={helperText}
      />
    </FormControl>
  );
};

export default TextInput;
