import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SuncleButton from '../SuncleButton/SuncleButton';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
  },
  cancel: {
    backgroundColor: '#e59924',
    '&:hover': {
      backgroundColor: '#e59924',
    },
    marginLeft: 24,
  },
}));

const CancelButton:React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <SuncleButton className={classes.cancel} onClick={() => history.goBack()}>Cancel</SuncleButton>
  );
};

export default CancelButton;
